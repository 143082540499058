import * as React from "react";
import {Fragment, useCallback} from "react";
import {
    AutocompleteInput,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
} from "react-admin";
import {Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";

import MobileGrid from "./MobileGrid";
import {Business} from "../../types";
import PayoutTransactionStatusField from "./PayoutTransactionStatusField";
import ReceiverNameField from "./ReceiverNameField";

const PayoutListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
);

const PayoutTransactionList = () => (
    <List
        filterDefaultValues={{status: 'Paid'}}
        sort={{field: 'createdAt', order: 'DESC'}}
        perPage={50}
        filters={payoutTransactionFilters}
        actions={<PayoutListActions/>}
    >
        <TabbedDataGrid/>
    </List>
);

const matchSuggestion = (filterValue:any, choice?:any) => choice?.businessProfile?.businessLegalName?.toLowerCase().match(filterValue?.toLowerCase());

const payoutTransactionFilters = [
    <SearchInput source="q" alwaysOn/>,
    <ReferenceInput source="businessId" reference="businesses" perPage={1000} >
        <AutocompleteInput
            matchSuggestion={matchSuggestion}
            optionText={(choice?: Business) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.businessProfile.businessLegalName}`
                    : ''
            }
        />
    </ReferenceInput>,
    <DateInput source="lastUpdatedAt"/>,
    <DateInput source="createdAt"/>,
    <SelectInput
        source="payoutState"
        choices={[
            {
                id: 'Draft',
                name: 'Draft',
            },
            {
                id: 'Pending',
                name: 'Pending',
            },
            {
                id: 'Accepted',
                name: 'Accepted',
            },
            {
                id: 'Sent',
                name: 'Sent',
            },
            {
                id: 'Resent',
                name: 'Resent',
            },
            {
                id: 'Rejected',
                name: 'Rejected',
            },
            {
                id: 'Expired',
                name: 'Expired',
            },
            {
                id: 'Cancelled',
                name: 'Cancelled',
            },
            {
                id: 'Withdrawn',
                name: 'Withdrawn',
            },
            {
                id: 'Submitted',
                name: 'Submitted',
            },
            {
                id: 'Copied',
                name: 'Copied',
            },
            {
                id: 'Paid',
                name: 'Paid',
            },
            {
                id: 'Failed',
                name: 'Failed',
            },
            {
                id: 'PendingApproval',
                name: 'PendingApproval',
            },
        ]

        }/>,
    <TextInput source="customerName"/>,
];


const tabs = [
    {id: 'Paid', name: 'Paid'},
    {id: 'Processed', name: 'Processed'},
    {id: 'Sent', name: 'Sent'},
    {id: 'Draft', name: 'Draft'},
    {id: 'Cancelled', name: 'Cancelled'},
];

const TabbedDataGrid = () => {
    const payoutListContext = useListContext();

    if (!Object.keys(payoutListContext?.filterValues)?.length) { // fix a bug with remove filter
        payoutListContext.filterValues["status"] = "Paid";
    }

    const {filterValues, setFilters, displayedFilters} = payoutListContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                {...filterValues, status: value},
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.name,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            {isXSmall ? (
                <MobileGrid/>
            ) : (
                <>
                    {filterValues.status === 'Paid' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <TextField source="payoutId" label="payout ID"/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <PayoutTransactionStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <TextField source="receiverMobileNumber" label="Receiver Mobile Number"/>
                            <ReceiverNameField source="receiverInformation" sortBy="receiverName" label="Receiver Name"/>
                            <DateField source="notificationSentDate" label="Notification Sent Date" showTime/>
                            <TextField source="invoiceNumber" label="Invoice Number"/>
                            <NumberField
                                source="payoutRequest.totalAmount"
                                label="Total Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Processed' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <TextField source="payoutId" label="payout ID"/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <PayoutTransactionStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <TextField source="receiverMobileNumber" label="Receiver Mobile Number"/>
                            <ReceiverNameField source="receiverInformation" sortBy="receiverName" label="Receiver Name"/>
                            <DateField source="notificationSentDate" label="Notification Sent Date" showTime/>
                            <TextField source="invoiceNumber" label="Invoice Number"/>
                            <NumberField
                                source="payoutRequest.totalAmount"
                                label="Total Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Sent' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <TextField source="payoutId" label="payout ID"/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <PayoutTransactionStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <TextField source="receiverMobileNumber" label="Receiver Mobile Number"/>
                            <ReceiverNameField source="receiverInformation" sortBy="receiverName" label="Receiver Name"/>
                            <DateField source="notificationSentDate" label="Notification Sent Date" showTime/>
                            <TextField source="invoiceNumber" label="Invoice Number"/>
                            <NumberField
                                source="payoutRequest.totalAmount"
                                label="Total Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Cancelled' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <TextField source="payoutId" label="payout ID"/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <PayoutTransactionStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <TextField source="receiverMobileNumber" label="Receiver Mobile Number"/>
                            <ReceiverNameField source="receiverInformation" sortBy="receiverName" label="Receiver Name"/>
                            <DateField source="notificationSentDate" label="Notification Sent Date" showTime/>
                            <TextField source="invoiceNumber" label="Invoice Number"/>
                            <NumberField
                                source="payoutRequest.totalAmount"
                                label="Total Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Draft' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <TextField source="payoutId" label="payout ID"/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <PayoutTransactionStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <TextField source="receiverMobileNumber" label="Receiver Mobile Number"/>
                            <ReceiverNameField source="receiverInformation" sortBy="receiverName" label="Receiver Name"/>
                            <DateField source="notificationSentDate" label="Notification Sent Date" showTime/>
                            <TextField source="invoiceNumber" label="Invoice Number"/>
                            <NumberField
                                source="payoutRequest.totalAmount"
                                label="Total Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                        </DatagridConfigurable>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default PayoutTransactionList;
