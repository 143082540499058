import * as React from 'react';
import {BooleanInput, FieldProps, useRecordContext} from 'react-admin';

import {Directory} from '../types';
import {Box, Typography} from "@mui/material";

const DirectoryDeleteField = (props: FieldProps<Directory>) => {
    const record = useRecordContext<Directory>();


    return (
        <Box display={{xs: 'block', sm: 'flex'}}>
            <Box flex={1} >
                <BooleanInput
                    row={true}
                    resource="directory"
                    source="deleteDirectory"
                    label='resources.directory.page.delete'
                    helperText={false}
                />
            </Box>
        </Box>
    );
};

DirectoryDeleteField.defaultProps = {
    source: 'directory_id',
    label: 'Directory ID'
};

export default DirectoryDeleteField;

