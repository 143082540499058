import * as React from 'react';
import {
    BooleanInput,
    DateTimeInput,
    Edit,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import {Box, Grid, Step, StepLabel, Stepper, Typography} from '@mui/material';

import {Billing} from "../types";
import BillingEditToolbar from './BillingEditToolbar';
import BillingIdField from "./BillingIdField";

const Separator = () => <Box pt="1em"/>;

const StateTimelines = () => {

    const record = useRecordContext<Billing>();
    // @ts-ignore
    const {stateTimeline = []} = record;
    const translate = useTranslate();
    const [activeStep, setActiveStep] = React.useState(stateTimeline.length);
    return (
        <Box sx={{maxWidth: 250}}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {stateTimeline.map((step: any) => (
                    <Step key={step.state}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">{new Date(step.date).toLocaleString()}</Typography>
                            }
                        >
                            {step.state}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

const TransactionListStepper = () => {

    const record = useRecordContext<Billing>();
    // @ts-ignore
    const {transactionList = []} = record;
    const translate = useTranslate();
    const [activeStep, setActiveStep] = React.useState(transactionList?.length);
    return (transactionList?.length) ? (
        <Box sx={{maxWidth: 250}}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {transactionList.map((step: any) => (
                    <Step key={step.state}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">{step.id}</Typography>
                            }
                        >
                            {step.state}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    ) : null;
};


const BillingEdit = () => {
    const translate = useTranslate();
    return (
        <Edit title={<BillingTitle/>}>
            <SimpleForm
                toolbar={<BillingEditToolbar/>}
            >
                <div>
                    <Grid container width={{xs: '100%', xl: 1400}} spacing={2}>
                        <Grid item xs={12} md={8}>


                            {/* Billing Request */}
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.billing.fieldGroups.billingRequest'
                                )}
                            </Typography>

                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <TextInput
                                        type={"string"}
                                        source="sk"
                                        label='resources.billing.page.billingRequest.billingPeriod'
                                        resource="billing"
                                        fullWidth
                                        multiline={true}
                                        resettable={true}
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <DateTimeInput
                                        source="startDate"
                                        label='resources.billing.page.billingRequest.startDate'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{xs: 0, sm: '0.5em'}}
                                >
                                    <DateTimeInput
                                        source="endDate"
                                        label='resources.billing.page.billingRequest.endDate'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <NumberInput
                                        source="totalTransactionFees"
                                        label='resources.billing.page.billingRequest.totalTransactionFees'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{xs: 0, sm: '0.5em'}}
                                >
                                    <DateTimeInput
                                        source="createdAt"
                                        label='resources.billing.page.billingRequest.createdAt'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <BooleanInput
                                        row={true}
                                        resource="billing"
                                        source="pdfDocUrl"
                                        label='resources.billing.page.billingRequest.invoiceDocAvailable'
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{xs: 0, sm: '0.5em'}}
                                >
                                    <TextInput
                                        source="pdfDocUrl"
                                        label='resources.billing.page.billingRequest.invoiceDocUrl'
                                        resource="billing"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <BooleanInput
                                        row={true}
                                        resource="billing"
                                        source="csvUrl"
                                        label='resources.billing.page.billingRequest.invoiceCsvAvailable'
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{xs: 0, sm: '0.5em'}}
                                >
                                    <TextInput
                                        source="csvUrl"
                                        label='resources.billing.page.billingRequest.invoiceCsvUrl'
                                        resource="billing"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>
                            {/* Business Details */}
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.billing.fieldGroups.billingDetails'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <NumberInput
                                        source="totalTransactionFees"
                                        format={(v: number) => Number(v).toFixed(2)}
                                        label='resources.billing.page.billingRequest.totalTransactionFees'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <NumberInput
                                        source="transactionFixedFee"
                                        format={(v: number) => Number(v).toFixed(2)}
                                        label='resources.billing.page.billingDetails.fixedFee'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{xs: 0, sm: '0.5em'}}
                                >
                                    <NumberInput
                                        source="monthlyFee"
                                        format={(v: number) => Number(v).toFixed(2)}
                                        label='resources.billing.page.billingDetails.monthlyFee'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <NumberInput
                                        source="transactionVariableFee"
                                        format={(v: number) => Number(v).toFixed(2)}
                                        label='resources.billing.page.billingDetails.transactionVariableFee'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{xs: 0, sm: '0.5em'}}
                                >
                                    <NumberInput
                                        source="totalBillingAmount"
                                        format={(v: number) => Number(v).toFixed(2)}
                                        label='resources.billing.page.billingDetails.totalBillingAmount'
                                        resource="billing"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box mt="1em"/>
                            <Separator/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.billing.fieldGroups.payment'
                                )}
                            </Typography>
                            <RenderSubmitPayment/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.billing.fieldGroups.identity'
                                )}
                            </Typography>
                            <div>
                                <SelectInput
                                    helperText={false}
                                    fullWidth
                                    resource="billing"
                                    source="billingState"
                                    label='resources.billing.page.billingState'
                                    choices={[
                                        {
                                            id: 'Draft',
                                            name: 'Draft',
                                        },
                                        {
                                            id: 'Empty',
                                            name: 'Empty',
                                        },
                                        {
                                            id: 'Invoiced',
                                            name: 'Invoiced',
                                        },
                                        {
                                            id: 'Requested',
                                            name: 'Requested',
                                        },
                                        {
                                            id: 'Cancelled',
                                            name: 'Cancelled',
                                        },
                                        {
                                            id: 'Paid',
                                            name: 'Paid',
                                        },
                                        {
                                            id: 'Failed',
                                            name: 'Failed',
                                        },

                                        {
                                            id: 'Voided',
                                            name: 'Voided',
                                        },
                                        {
                                            id: 'Returned',
                                            name: 'Returned',
                                        },
                                        {
                                            id: 'Error',
                                            name: 'Error',
                                        },
                                        {
                                            id: 'Submitted',
                                            name: 'Submitted',
                                        },
                                        {
                                            id: 'Processed',
                                            name: 'Processed',
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="sk"
                                    source="sk"
                                    resource="billing"
                                    label='resources.billing.page.billingPeriod'
                                    disabled={true}
                                    helperText={false}
                                    fullWidth
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="businessId"
                                    source="businessId"
                                    resource="billing"
                                    label='resources.billing.page.businessId'
                                    validate={[required()]}
                                    disabled={true}
                                    helperText={false}
                                    fullWidth
                                />
                            </div>
                            <div>
                                <DateTimeInput
                                    source="createdAt"
                                    resource="billing"
                                    label='resources.billing.page.createdAt'
                                    helperText={false}
                                    disabled={true}
                                    fullWidth
                                />
                            </div>
                            <Separator/>
                            <Box mt="1em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.stateTimeline'
                                )}
                            </Typography>
                            <StateTimelines/>
                            <Separator/>
                            <Box mt="1em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.billing.fieldGroups.transactionList'
                                )}
                            </Typography>
                            <TransactionListStepper/>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

const BillingTitle = () => {
    const record = useRecordContext<Billing>();
    return record ? <BillingIdField record={record} size="32"/> : null;
}

const RenderSubmitPayment = () => {
    const record = useRecordContext<Billing>();
    return (record?.billingState === "Invoiced") ?
        (
            <Box display={{xs: 'block', sm: 'flex'}}>
                <Box
                    flex={1}
                    mr={{xs: 0, sm: '0.5em'}}
                >
                    <BooleanInput
                        row={true}
                        resource="businesses"
                        source="submitBilling"
                        label='resources.billing.page.submitBilling'
                        fullWidth
                    />
                </Box>
            </Box>
        ) : (record?.submitBilling) ?
            (
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="submitBilling"
                            label='resources.billing.page.billingSubmitted'
                            fullWidth
                            disabled={true}
                        />
                    </Box>
                </Box>
            ) :
            (
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="submitBilling"
                            label='resources.billing.page.notInvoiced'
                            fullWidth
                            disabled={true}
                        />
                    </Box>
                </Box>
            );
}

export default BillingEdit;
