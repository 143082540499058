// in src/comments.js
import * as React from 'react';
import {Box, Card, CardContent, CardHeader, Typography} from '@mui/material';
import {
    DateField,
    EditButton,
    NumberField,
    RaRecord,
    RecordContextProvider,
    ReferenceField,
    TextField,
    useListContext,
    useTranslate,
} from 'react-admin';
import {Transaction} from '../../types';
import PayoutTransactionStatusField from "./PayoutTransactionStatusField";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {
    const {data, isLoading} = useListContext<Transaction>();
    const translate = useTranslate();
    if (isLoading || data.length === 0) {
        return null;
    }
    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{margin: '0.5rem 0'}}>
                        <CardHeader
                            title={
                                <>
                                    {translate('resources.r2p.page.transactionId', 1)} #
                                    <TextField
                                        source="transactionId"
                                        variant="body1"
                                    />
                                </>
                            }
                            titleTypographyProps={{variant: 'body1'}}
                            action={<EditButton/>}
                        />
                        <CardContent sx={{pt: 0}}>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField
                                    source="businessProfile.businessLegalName"
                                />
                                sx={{display: 'block', mb: 1}}
                            </ReferenceField>

                            <Typography variant="body2" gutterBottom>
                                {translate('resources.r2p.page.createdAt')}
                                :&nbsp;
                                <DateField source="createdAt" label="Created Date" showTime/>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {translate(
                                    'resources.r2p.page.paymentRequest.totalAmount'
                                )}
                                :&nbsp;
                                <NumberField
                                    source="totalAmount"
                                    label="Total Amount"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    sx={{fontWeight: 'bold'}}
                                />
                            </Typography>
                            <PayoutTransactionStatusField/>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
