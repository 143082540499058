// in src/comments.js
import * as React from 'react';
import {Box, Card, CardContent, CardHeader, Typography} from '@mui/material';
import {DateField, EditButton, RecordContextProvider, TextField, useListContext, useTranslate,} from 'react-admin';

import {Directory} from '../types';

const MobileGrid = () => {
    const translate = useTranslate();
    const {data, isLoading} = useListContext<Directory>();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>

                    <Card sx={{margin: '0.5rem 0'}}>

                        {record.consumerMobileNumber ?
                            (
                                <CardHeader
                                    title={
                                        <>
                                            {translate('resources.directory.page.consumerMobileNumber', 1)} #
                                            <TextField
                                                source="consumerMobileNumber"
                                                variant="body1"
                                            />
                                        </>
                                    }
                                    titleTypographyProps={{variant: 'body1'}}
                                    action={<EditButton/>}
                                />

                            ) :
                            (
                                <CardHeader
                                    title={
                                        <>
                                            {translate('resources.directory.page.businessId', 1)} #
                                            <TextField
                                                source="businessId"
                                                variant="body1"
                                            />
                                        </>
                                    }
                                    titleTypographyProps={{variant: 'body1'}}
                                    action={<EditButton/>}
                                />
                            )
                        }

                        <CardContent sx={{pt: 0}}>
                            <Typography variant="body2">
                                {translate(
                                    'resources.directory.page.directoryId',
                                )}
                                :&nbsp;
                                <TextField source="directoryId"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.directory.page.bankName',
                                )}
                                :&nbsp;
                                <TextField source="accountInfo.bankName"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.directory.page.displayAccountNumber',
                                )}
                                :&nbsp;
                                <TextField source="accountInfo.displayAccountNumber"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.directory.page.displayRoutingNumber',
                                )}
                                :&nbsp;
                                <TextField source="accountInfo.displayRoutingNumber"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.directory.page.serviceName',
                                )}
                                :&nbsp;
                                <TextField source="accountInfo.achInfo.serviceName"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.directory.page.lastUpdatedAt',
                                )}
                                :&nbsp;
                                <DateField source="lastUpdatedAt"/>
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;
