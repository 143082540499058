import * as React from "react";
import {BooleanInput, TextInput, useRecordContext, useTranslate} from "react-admin";
import {Business} from "../types";
import {Box, Typography} from "@mui/material";

const Separator = () => <Box pt="1em"/>;

const CardKnoxFeatureInfo = () => {
    const record = useRecordContext<Business>();
    const translate = useTranslate();
    if (!record) return null;
    if (record?.merchantKeys?.cardknox?.errorCode) {
        return (
            <div>
                <Separator/>
                <Typography variant="subtitle1" gutterBottom sx={{
                    color: "#40BFD9",
                    fontStyle: "italic"
                }}>
                    {translate(
                        'resources.businesses.page.merchantKey.cardKnox.label'
                    )}
                </Typography>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="cardKnoxResubmit"
                            label='resources.businesses.features.cardknoxResubmit'
                            fullWidth
                        />
                    </Box>
                </Box>
                <Separator/>
                <Separator/>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="optOutCardKnox"
                            label='resources.businesses.features.optOutCardKnox'
                            fullWidth
                        />
                    </Box>
                </Box>
            </div>
        );
    } else if (record?.merchantKeys?.cardknox?.xKey) {
        return (
            <div>
                <Separator/>
                <Typography variant="subtitle1" gutterBottom sx={{
                    color: "#40BFD9",
                    fontStyle: "italic"
                }}>
                    {translate(
                        'resources.businesses.page.merchantKey.cardKnox.label'
                    )}
                </Typography>
                <TextInput
                    source="merchantKeys.cardknox.xKey"
                    resource="businesses"
                    label='resources.businesses.page.merchantKey.cardKnox.xKey'
                    helperText={false}
                    fullWidth
                />
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="cardKnoxEnabled"
                            label='resources.businesses.features.cardknox'
                            fullWidth
                        />
                    </Box>
                </Box>
                <Separator/>
                <Separator/>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="optOutCardKnox"
                            label='resources.businesses.features.optOutCardKnox'
                            fullWidth
                        />
                    </Box>
                </Box>
            </div>
        );
    } else if (record?.merchantKeys?.cardknox?.onboardingSubmitted) {
        return (
            <div>
                <Separator/>
                <Typography variant="subtitle1" gutterBottom sx={{
                    color: "#40BFD9",
                    fontStyle: "italic"
                }}>
                    {translate(
                        'resources.businesses.page.merchantKey.cardKnox.label'
                    )}
                </Typography>
                <TextInput
                    source="merchantKeys.cardknox.xKey"
                    resource="businesses"
                    label='resources.businesses.page.merchantKey.cardKnox.xKey'
                    helperText={false}
                    fullWidth
                />
                <Separator/>
                <Separator/>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box
                        flex={1}
                        mr={{xs: 0, sm: '0.5em'}}
                    >
                        <BooleanInput
                            row={true}
                            resource="businesses"
                            source="optOutCardKnox"
                            label='resources.businesses.features.optOutCardKnox'
                            fullWidth
                        />
                    </Box>
                </Box>
            </div>
        );
    } else if (record?.consumerPaymentMethods?.eligible?.length) {
        return ckEligiblePresent(record)
    } else {
        return null;
    }
};

function ckEligiblePresent(record: any) {
    //const record = useRecordContext<Business>();
    if (record?.consumerPaymentMethods?.eligible?.length) {
        const cpmEligibleList = record?.consumerPaymentMethods?.eligible;
        //const translate = useTranslate();
        let ckPresent = false;
        cpmEligibleList.forEach((item: any) => {
            if (item?.paymentMethod === 'CARDS' && item?.paymentProcessor === 'CARDKNOX') {
                ckPresent = true;
            }
        });
        if (!ckPresent) {
            return (
                <div>
                    <Separator/>
                    <Typography variant="subtitle1" gutterBottom sx={{
                        color: "#40BFD9",
                        fontStyle: "italic"
                    }}>
                        Card Knox
                    </Typography>
                    <Box display={{xs: 'block', sm: 'flex'}}>
                        <Box
                            flex={1}
                            mr={{xs: 0, sm: '0.5em'}}
                        >
                            <BooleanInput
                                row={true}
                                resource="businesses"
                                source="updateEligibleCpmCardKnox"
                                label='resources.businesses.features.cardknoxUpdateEligible'
                                fullWidth
                            />
                        </Box>
                    </Box>
                </div>
            );
        } else {
            return (
                <div>
                    <Separator/>
                    <Separator/>
                    <Typography variant="subtitle1" gutterBottom sx={{
                        color: "#40BFD9",
                        fontStyle: "italic"
                    }}>
                        Card Knox
                    </Typography>
                    <Box display={{xs: 'block', sm: 'flex'}}>
                        <Box
                            flex={1}
                            mr={{xs: 0, sm: '0.5em'}}
                        >
                            <BooleanInput
                                row={true}
                                resource="businesses"
                                source="optOutCardKnox"
                                label='resources.businesses.features.optOutCardKnox'
                                fullWidth
                            />
                        </Box>
                    </Box>
                </div>
            );
        }
    } else {
        return null;
    }
}


/*

merchantKey: {
    cardKnox: {
        label: "Card Knox",
            xKey: "X-Key",
            onboardingSubmitted: "Onboarding Submitted",
            paymentSiteUrl: "Payment Site URL",
            processor: "Processor",
            dateUpdated: "Date Updated",
            agreementToken: "Agreement Token",
            appId: "App ID",
    }
}

    "merchantKeys": {
        "cardknox": {
            "xKey": "taysubaccou1dev7393b563cd704574857d005f4b144d",
            "agreementToken": "107.205.9.23;sut_02_0_agreement_01_20230707T074323Z_7200_hf-rnfg-2_941e41c23c56417cb95bb1530203bc3a_818016ecc2f2460eec290967179273f79b9aaf54d877783bbce1b2cacc807b27",
            "onboardingSubmitted": true,
            "refnum": "brd121006092",
            "appId": 182021,
            "pendingPaymentSiteUrl": "https://secure.cardknox.com/TestCampecheCoffee3",
            "processor": "CARDKNOX",
            "dateUpdated": "2023-07-07T07:43:29.604Z"
        }
    },

*/


export default CardKnoxFeatureInfo;
