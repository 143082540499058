import * as React from "react";
import {deepPurple,} from "@mui/material/colors";
import {TextField, useRecordContext} from "react-admin";
import {Revenue} from "../types";

const RevenueStatusField = () => {
    const record = useRecordContext<Revenue>();
    return (
        <TextField
            record={record}
            source="revenueState"
            sx={{color: getStatusColor(record?.revenueState)}}
        />
    );
};

RevenueStatusField.defaultProps = {
    source: "revenueState",
    label: "Revenue State",
};

function getStatusColor(state: string | undefined) {
    if (state) {
        return deepPurple["900"];
    }

    // fallback
    return "#000000";
}

export default RevenueStatusField;
