import * as React from "react";
import {memo} from "react";
import {FieldProps} from "react-admin";
import {Payout} from "../../types";

interface Props extends FieldProps<Payout> {
    size?: string;
}

const ReceiverNameField = (props: Props) => {
    const {record, size} = props;
    return record ? (
        <div>
            {record?.receiverInformation?.firstName} {record?.receiverInformation?.lastName}
        </div>
    ) : null;
};

ReceiverNameField.defaultProps = {
    source: 'receiverInformation.firstName',
    label: 'resources.r2p.fields.name',
};

export default memo<Props>(ReceiverNameField);
