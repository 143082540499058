import * as React from 'react';
import DollarIcon from '@mui/icons-material/AttachMoney';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const MonthlyPayoutAmount = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/payouts"
            icon={DollarIcon}
            title={translate('pos.dashboard.monthly_payout_revenue')}
            subtitle={value}
        />
    );
};

export default MonthlyPayoutAmount;
