import * as React from "react";
import {
  blue,
  blueGrey,
  deepOrange,
  deepPurple,
  green,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import {TextField, TextInput, useRecordContext} from "react-admin";
import {Directory} from "../types";
import {Typography} from "@mui/material";

const DirectoryTypeField = () => {
  const record = useRecordContext<Directory>();
  const type =  (record?.consumerMobileNumber) ? "Consumer": "Business";
  return (

  <TextInput
      type="directoryType"
      source="directoryType"
      resource="directory"
      fullWidth
      label='resources.directory.page.directoryType'
      disabled={true}
      helperText={false}
  />
  );
};

DirectoryTypeField.defaultProps = {
  source: "directoryStatus",
  label: "Directory Status",
};

function getStatusColor(state: string | undefined) {
  if (state) {
    return deepPurple["900"];
  }

  // fallback
  return "#000000";
}

export default DirectoryTypeField;
