import * as React from 'react';
import {useRecordContext} from 'react-admin';
import {Business} from '../types';
import BusinessStatusField from "./BusinessStatusField";

const DisplayAddressField = () => {
    const record = useRecordContext<Business>();
    return record ? (
        <span>
            {record?.businessAddress?.addressLineOne}, {record?.businessAddress?.city}, {record?.businessAddress?.addressState} {record?.businessAddress?.zipcode}
        </span>
    ) : null;
}

DisplayAddressField.defaultProps = {
    source: "businessAddress",
    label: "Business Address",
};

export default DisplayAddressField;
