import * as React from 'react';
import {FieldProps, ImageField, useRecordContext} from 'react-admin';

import {Directory} from '../types';

const InstitutionLogoField = (props: FieldProps<Directory>) => {
    const record = useRecordContext<Directory>();
    if (!record) {
        return null;
    }

    const imageData = {
        title: record.accountInfo.bankName,
        url: `data:image/png;base64, ${record.accountInfo.institutionLogo}`
    }

    return (
        <ImageField
            record={imageData}
            source="url"
            title="title"
            sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }}
        />
    );
};

InstitutionLogoField.defaultProps = {
    source: "logo",
    label: 'Institution Logo'
};

export default InstitutionLogoField;
