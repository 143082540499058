import * as React from 'react';
import {FieldProps, useRecordContext} from 'react-admin';

import {Directory} from '../types';
import {Typography} from "@mui/material";

const DirectoryNameField = (props: FieldProps<Directory>) => {
    const record = useRecordContext<Directory>();
    if (!record) {
        return null;
    }
    return (
        <Typography
            variant="body2"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            component="div"
            sx={{margin: '5px 0'}}
        >
            {record.directoryId}
        </Typography>
    );
};

DirectoryNameField.defaultProps = {
    source: 'directoryId',
    label: 'Directory'
};

export default DirectoryNameField;
