import * as React from "react";
import {
    ArrayField,
    Datagrid,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
    useTranslate
} from "react-admin";
import {Business} from "../types";
import {Box, Divider, Typography} from "@mui/material";

const Separator = () => <Box pt="1em"/>;

export const ShowErrorBlock = () => {
    const record = useRecordContext<Business>();
    const translate = useTranslate();
    if (record?.merchantKeys?.cardknox?.errorCode) {
        return (
            <div>
                <Separator/>
                <Box mt="1em"/>
                <Typography variant="subtitle1" gutterBottom sx={{
                    color: "red",
                    fontStyle: "italic"
                }}>
                    {translate(
                        'resources.businesses.page.merchantKey.cardKnox.onboardingErrorMessage'
                    )}
                </Typography>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <TextInput
                            source="merchantKeys.cardknox.errorCode"
                            resource="businesses"
                            label='resources.businesses.page.merchantKey.cardKnox.errorCode'
                            multiline
                            fullWidth
                            helperText={false}
                        />
                    </Box>
                </Box>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                        <SimpleShowLayout spacing={2} divider={<Divider flexItem/>} sx={{
                            border: '1px solid', borderRadius: '3px', color: 'lightgray'
                        }}>
                            <ArrayField source="merchantKeys.cardknox.errorMessages" label=''>
                                <Datagrid bulkActionButtons={false} sx={{
                                    background: "#F5F5F5"
                                }}>
                                    <TextField source="message"
                                               label='resources.businesses.page.merchantKey.cardKnox.errorMessage'/>
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                    </Box>
                </Box>
            </div>
        );
    } else {
        return null;
    }
}
