import simpleRestProvider from "ra-data-simple-rest";
import {DataProvider, fetchUtils} from "react-admin";
import {Auth} from "@aws-amplify/auth";

const devEnvironment = {
    BUSINESS_CONTEXT: "https://api.dev.taywepay.com/b/admin",
    CONSUMER_CONTEXT: "https://api.dev.taywepay.com/c",
    PAYMENT_CONTEXT: "https://api.dev.taywepay.com/p/admin",
    PAYOUT_CONTEXT: "https://api.dev.taywepay.com/r/admin",
    DIRECTORY_CONTEXT: "https://api.dev.taywepay.com/d/admin",
};

const demoEnvironment = {
    BUSINESS_CONTEXT: "https://api.demo.taywepay.com/b/admin",
    CONSUMER_CONTEXT: "https://api.demo.taywepay.com/c",
    PAYMENT_CONTEXT: "https://api.demo.taywepay.com/p/admin",
    PAYOUT_CONTEXT: "https://api.demo.taywepay.com/r/admin",
    DIRECTORY_CONTEXT: "https://api.demo.taywepay.com/d/admin",
};

const productionEnvironment = {
    BUSINESS_CONTEXT: 'https://api.taynow.com/b/admin',
    CONSUMER_CONTEXT: 'https://api.taynow.com/c',
    PAYMENT_CONTEXT: 'https://api.taynow.com/p/admin',
    PAYOUT_CONTEXT: "https://api.taynow.com/r/admin",
    DIRECTORY_CONTEXT: "https://api.taynow.com/d/admin",
};

const config = {
    Api_context: {
        BUSINESS_CONTEXT: 'https://api.dev.taywepay.com/b/admin',
        CONSUMER_CONTEXT: 'https://api.dev.taywepay.com/c',
        PAYMENT_CONTEXT: 'https://api.dev.taywepay.com/p/admin',
        PAYOUT_CONTEXT: "https://api.dev.taywepay.com/r/admin",
        DIRECTORY_CONTEXT: 'https://api.dev.taywepay.com/d/admin',
    },
};

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
const isDemo = process.env.REACT_APP_NODE_ENV === 'demo';
const isDev = process.env.REACT_APP_NODE_ENV === 'dev';

if (isDev) {
    config.Api_context = {...config.Api_context, ...devEnvironment};
} else if (isDemo) {
    config.Api_context = {...config.Api_context, ...demoEnvironment};
} else if (isProduction) {
    config.Api_context = {...config.Api_context, ...productionEnvironment};
}

const httpClient = async (url: any, options: any = {}) => {

    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json',
        });
    }

    await Auth.currentSession().then(
        (session: any) => {
            options.mode = 'cors';
            options.user = {
                authenticated: true,
                token: 'Bearer ' + session.idToken.jwtToken,
            };
            options.headers.set(
                'Access-Control-Request-Headers',
                'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Amz-User-Agent,Content-Range'
            );
            options.headers.set('user-type', 'admin');
        },
        err => {
            console.log('no additional headers');
        }
    );

    console.log('fetching..', url);

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(config.Api_context.BUSINESS_CONTEXT, httpClient);

const tayDataProvider: DataProvider = {
    ...dataProvider,
    getOne: (resource, params) => {
        resource = updateResource(resource);
        updateApiContext(resource, dataProvider);
        return getDataProvider(resource).getOne(resource, params);
    },
    getList: (resource, params) => {
        resource = updateResource(resource);
        return getDataProvider(resource).getList(resource, params);
    },
    getMany: (resource, params) => {
        resource = updateResource(resource);
        updateApiContext(resource, dataProvider);
        return getDataProvider(resource).getMany(resource, params);
    },
    update: (resource, params) => {
        resource = updateResource(resource);
        updateApiContext(resource, dataProvider);
        return getDataProvider(resource).update(resource, params);
    },
};

/**
 * interceptor to update the API resource end point if required
 *
 * @param resource
 */
function updateResource(resource: string) {
    if (resource === "relatedTransactions") {
        resource = "transactions";
    }
    return resource;
}

function updateApiContext(resource: string, dataProvider: any) {

    // update for resources other than businesses
    if (resource === 'consumer') {
        dataProvider.apiUrl = config.Api_context.CONSUMER_CONTEXT;
    } else if (resource === 'transactions') {
        dataProvider.apiUrl = config.Api_context.PAYMENT_CONTEXT;
    } else if (resource === 'relatedTransactions') {
        dataProvider.apiUrl = config.Api_context.PAYMENT_CONTEXT;
    } else if (resource === 'billing') {
        dataProvider.apiUrl = config.Api_context.PAYMENT_CONTEXT;
    } else if (resource === 'businessBilling') {
        dataProvider.apiUrl = config.Api_context.PAYMENT_CONTEXT;
    } else if (resource === 'categories') {
        dataProvider.apiUrl = config.Api_context.BUSINESS_CONTEXT;
    } else if (resource === 'payouts') {
        dataProvider.apiUrl = config.Api_context.PAYOUT_CONTEXT;
    } else if (resource === 'receivers') {
        dataProvider.apiUrl = config.Api_context.PAYOUT_CONTEXT;
    } else if (resource === 'revenue') {
        dataProvider.apiUrl = config.Api_context.PAYMENT_CONTEXT;
    } else if (resource === 'directory') {
        dataProvider.apiUrl = config.Api_context.DIRECTORY_CONTEXT;
    }
}

function getDataProvider(resource: string) {
    if (resource === 'consumer') {
        return simpleRestProvider(config.Api_context.CONSUMER_CONTEXT, httpClient);
    } else if (resource === 'transactions') {
        return simpleRestProvider(config.Api_context.PAYMENT_CONTEXT, httpClient);
    } else if (resource === 'relatedTransactions') {
        return simpleRestProvider(config.Api_context.PAYMENT_CONTEXT, httpClient);
    } else if (resource === 'billing') {
        return simpleRestProvider(config.Api_context.PAYMENT_CONTEXT, httpClient);
    } else if (resource === 'businessBilling') {
        return simpleRestProvider(config.Api_context.PAYMENT_CONTEXT, httpClient);
    } else if (resource === 'categories') {
        return simpleRestProvider(config.Api_context.BUSINESS_CONTEXT, httpClient);
    } else if (resource === 'payouts') {
        return simpleRestProvider(config.Api_context.PAYOUT_CONTEXT, httpClient);
    } else if (resource === 'receivers') {
        return simpleRestProvider(config.Api_context.PAYOUT_CONTEXT, httpClient);
    } else if (resource === 'revenue') {
        return simpleRestProvider(config.Api_context.PAYMENT_CONTEXT, httpClient);
    } else if (resource === 'directory') {
        return simpleRestProvider(config.Api_context.DIRECTORY_CONTEXT, httpClient);
    } else {
        return dataProvider;
    }
}

export default tayDataProvider;
