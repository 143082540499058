import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import { useTranslate } from 'react-admin';

// @ts-ignore
import publishArticleImage from './images/hero-woman-wcontent-area@3x.png';

const Welcome = () => {
    const translate = useTranslate();

    return (
        <Card
            sx={{
                background: theme =>
                    theme.palette.mode === 'dark'
                        ? '#535353'
                        : `linear-gradient(to right, #277889 10%, #FFFFFF 70%), linear-gradient(to bottom, #3dbbd6 0%, #238da4 50%), #238da4`,

                color: '#fff',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            <Box display="flex">
                <Box flex="1"
                     sx={{
                         padding: '15px',
                     }}
                >
                    <Typography variant="h5" component="h2" gutterBottom>
                        {translate('pos.dashboard.welcome.title')}
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            {translate('pos.dashboard.welcome.subtitle')}
                        </Typography>
                    </Box>
                    <CardActions
                        sx={{
                            padding: { xs: 0, xl: null },
                            flexWrap: { xs: 'wrap', xl: null },
                            '& a': {
                                marginTop: { xs: '1em', xl: null },
                                marginLeft: { xs: '0!important', xl: null },
                                marginRight: { xs: '1em', xl: null },
                            },
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                background: '#1a697a',
                                '&:hover': {
                                    color: '#000',
                                    backgroundColor: '#fff',
                                },
                            }}
                            href="https://taynow.com"
                            startIcon={<HomeIcon />}
                        >
                            {translate('pos.dashboard.welcome.ra_button')}
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                background: '#1a697a',
                                '&:hover': {
                                    color: '#000',
                                    backgroundColor: '#fff',
                                },
                            }}
                            href="https://www.business.taynow.com/login"
                            startIcon={<CodeIcon />}
                        >
                            {translate('pos.dashboard.welcome.demo_button')}
                        </Button>
                    </CardActions>
                </Box>
                <Box
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    sx={{
                        background: `url(${publishArticleImage}) top right / cover`,
                        marginLeft: 'auto',
                    }}
                    width="26em"
                    height="11em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;
