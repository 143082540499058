// in src/comments.js
import * as React from 'react';
import {Box, Card, CardContent, CardHeader, Typography} from '@mui/material';
import {
    DateField,
    EditButton,
    NumberField,
    RecordContextProvider,
    TextField,
    useListContext,
    useTranslate,
} from 'react-admin';

import {Business} from '../types';
import AvatarField from "./AvatarField";

const MobileGrid = () => {
    const translate = useTranslate();
    const {data, isLoading} = useListContext<Business>();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{margin: '0.5rem 0'}}>
                        <CardHeader
                            title={`${record.ownerDetails.firstName} ${record.ownerDetails.lastName}`}
                            subheader={
                                <>
                                    {translate(
                                        'resources.businesses.page.businessProfile.createdAt'
                                    )}
                                    &nbsp;
                                    <DateField source="createdAt"/>
                                </>
                            }
                            avatar={<AvatarField size="45"/>}
                            action={<EditButton/>}
                        />
                        <CardContent sx={{pt: 0}}>
                            <Typography variant="body2">
                                {translate(
                                    'resources.businesses.page.businessProfile.businessLegalName',
                                )}
                                :&nbsp;
                                <TextField source="businessProfile.businessLegalName"/>
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.businesses.page.businessProfile.clientId'
                                )}
                                :&nbsp;
                                <NumberField
                                    source="clientId"
                                />
                            </Typography>
                        </CardContent>
                        <CardContent sx={{pt: 0}}>
                            <Typography variant="body2">
                                {translate(
                                    'resources.businesses.page.businessState',
                                )}
                                :&nbsp;
                                <TextField source="businessState"/>
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;
