import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Pay-In Amount',
            all_revenue: 'Total Pay-In Amount',
            all_payout_revenue: 'Total Pay-Out Amount',
            monthly_payout_revenue: 'Monthly Pay-Out Amount',
            month_history: '30 Day Transaction History',
            month_history_payout: '30 Day Payout History',
            new_orders: '30 Day Transactions',
            new_payout_orders: '30 Day Payouts',
            pending_reviews: 'Pending Transactions',
            all_reviews: 'See all transactions',
            new_customers: 'New Businesses',
            all_customers: 'See all businesses',
            pending_orders: 'Pending Transactions',
            transaction: {
                items:
                    'for %{customer_name}, by %{business_name}',
            },
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the TAY administration',
                subtitle:
                    "This is the admin page of tay platform - the fastest way to get paid for service based business!.",
                ra_button: 'tay home page',
                demo_button: 'tay business page',
            },
        },
        menu: {
            users: "Users",
            r2p: "R2P",
            billing: "Billing",
            payout: "Payout",
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        linked2Pay: {
            name: "Linked2Pay |||| Payments",
            fields: {
                commands: "Orders",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity",
            },
            page: {
                delete: "Delete Customer",
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        r2p: {
            name: "R2P |||| Transactions",
            fields: {
                commands: "Orders",
                name: "Transaction List",
                owner_name: "Business Owner Name",
                save: "Save",
                submit: "Submit Transaction to ACH",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity & Status",
                stateTimeline: "Status Time Line (PST)",
                status: "Transaction State",
                paymentRequest: "Payment Request",
                businessInformation: "Business Information",
                consumerInformation: "Consumer Information",
                transactionOtherInfo: "Transaction Other Information",
                l2pResponseData: "L2P response Data",
            },
            page: {
                id: "ID",
                transactionState: "Transaction state",
                transactionId: "Transaction ID",
                businessId: "Business ID",
                userId: "User ID",
                consumerMobileNumber: "Customer Mobile Number",
                invoiceNumber: "Invoice Number",
                createdAt: "Created at",
                notificationSentDate: "Notification sent date",
                timeToLive: "Time to live",
                stateTimeline: "State time line",
                addresses: "Addresses",
                businessInformation: {
                    firstName: "First name",
                    lastName: "Last name",
                    businessEmail: "Business email",
                    businessLegalName: "Business legal name",
                    clientId: "Client ID",
                    accountInformation: {
                        details: "Business Account Details",
                        businessDirectoryId: "Business directory ID",
                        businessDisplayAccountNumber: "Business display account number",
                        businessBankName: "Business bank name",
                    },
                    businessAddress: {
                        address: "Business Address",
                        addressLineOne: "Address line one",
                        addressLineTwo: "Address line two",
                        city: "City",
                        addressState: "State",
                        zipcode: "Zipcode"
                    },
                    logo: {
                        details: "Logo",
                        logoAvailable: "Logo available",
                        displayLogoUrl: "Logo display URL",
                    },
                },
                consumerInformation: {
                    details: "Consumer Identity",
                    consumerMobileNumber: "Consumer mobile number",
                    email: "Email",
                    firstName: "First name",
                    lastName: "Last name",
                    accountInformation: {
                        details: "Consumer Account Details",
                        consumerDirectoryId: "Consumer directory ID",
                        consumerDisplayAccountNumber: "Consumer display account number",
                        consumerBankName: "Consumer bank name",
                        institutionLogo: "Institution logo",
                    },
                },
                paymentRequest: {
                    paymentDescription: "Payment description",
                    serviceDate: "Service date",
                    serviceAmount: "Service amount",
                    taxPercentage: "Tax percentage",
                    totalAmount: "Total amount",
                    referenceInvoiceNumber: "Reference invoice number",
                    invoiceId: "Invoice ID",
                    invoiceDocAvailable: "Invoice doc available",
                    submitACH: "submit ACH",
                    invoiceDocUrl: "Invoice doc URL",
                },
                transactionOtherInfo: {
                    resendCount: "Resend count",
                },
                l2pResponseData: {
                    transactionRsp: {
                        contactProfileId: "Contact profile ID",
                        transPymtId: "Transaction payment ID",
                        accountMask: "Account mask",
                        transId: "Transaction ID",
                        paymentProfileId: "Payment profile ID",
                        accountType: "Account type",
                        status: {
                            responseCode: "Response code",
                        },
                    },
                },
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        users: {
            name: "Users |||| Users",
            fields: {
                commands: "Orders",
                email: "Email",
                firstName: "First name",
                lastName: "Last name",
                mobileNumber: "Mobile number",
                enrollNotificationViaEmail: "Email notification",
                enrollNotificationViaMobileNumber: "Mobile number notification",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity",
                address: "Address",
                notification: "Notification Preferences",
                change_password: "Change Password",
                reset_password: "Reset Password",
                stats: "Stats",
                history: "History",
                password: "Temporary Password",
                status: "Status",
                userState: "User Status",
            },
            page: {
                delete: "Delete Customer",
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        billing: {
            name: "Billing |||| All Clients",
            tpv: "Txn Volume",
            business: {
                name: "Business |||| Business",
            },
            fields: {
                commands: "Orders",
                name: "Billing List",
                owner_name: "Business Owner Name",
                save: "Save",
                submit: "Submit Transaction to ACH",
                detail: "Billing Detail",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity & Status",
                billingRequest: "Billing Request",
                billingDetails: "Billing Details",
                transactionList: "Transaction ID List",
                status: "Billing State",
                payment: "Payment",
            },
            page: {
                billingState: "Billing state",
                billingPeriod: "Billing  period",
                businessId: "Business ID",
                createdAt: "Created at",
                transactionList: "Transaction List",
                billingRequest: {
                    billingPeriod: "Billing period",
                    startDate: "Start date",
                    endDate: "End date",
                    createdAt: "Created at",
                    invoiceDocUrl: "Invoice doc URL",
                    invoiceCsvUrl: "Invoice doc CSV",
                    totalTransactionFees: "Total transaction fees",
                    invoiceDocAvailable: "Invoice doc available",
                    invoiceCsvAvailable: "Invoice CSV available",
                },
                billingDetails: {
                    fixedFee: "Fixed Fee Per Transaction",
                    monthlyFee: "Monthly Fee",
                    transactionVariableFee: "Transaction Variable Fee %",
                    totalTransactionFees: "Total Transaction Fees",
                    totalBillingAmount: "Total Billing Amount",
                },
                submitBilling: "Submit billing request",
                billingSubmitted: "Billing already submitted",
                notInvoiced: "Not invoiced yet",
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        businessBilling: {
            name: "Billing |||| Billing List For Business",
        },
        businesses: {
            name: "Businesses |||| Business List",
            fields: {
                commands: "Orders",
                name: "Business List",
                owner_name: "Business Owner Name",
                save: "Save",
                submit: "Onboard Client to L2P",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity & Status",
                features: "Features",
                status: "Business State",
                businessProfile: "Business Profile",
            },
            features: {
                payout: "Enable/Disable Payout",
                cardknox : "Enable/Disable CC by CardKnox",
                cardknoxResubmit : "Resubmit CardKnox Onboarding",
                cardknoxUpdateEligible : "Add CardKnox as eligible payment method",
                optOutCardKnox: "Opt out Card Knox",
                payoutLabel: "Payout",
                receiptLabel: "Receipt",
                receipt: "Enable/Disable TID in Receipt",
            },
            page: {
                delete: "Delete Customer",
                addresses: "Address",
                businessProfile: {
                    businessLegalName: "Business legal name",
                    businessLicense: "Business license",
                    doingBusinessAsName: "Business as name",
                    businessType: "Business type",
                    taxPayerId: "Tax payer ID",
                    yearsInBusiness: "Years in business",
                    corpStructure: "Corp structure",
                    mccCode: "MCC code",
                    logoAvailable: "Logo Available",
                    logoUrl: "Logo Url",
                    businessState: "Business State",
                    businessId: "Business ID",
                    userId: "user ID",
                    clientId: "Client ID",
                    contactPhone: "Contact phone",
                    createdAt: "Created at",
                },
                businessAddress: {
                    address: "Business Address",
                    addressLineOne: "Address line one",
                    addressLineTwo: "Address line two",
                    city: "City",
                    addressState: "State",
                    zipcode: "Zipcode"
                },
                mailingAddress: {
                    address: "Mailing Address",
                    addressLineOne: "Address line one",
                    addressLineTwo: "Address line two",
                    city: "City",
                    addressState: "State",
                    zipcode: "Zipcode"
                },
                paymentProfile: {
                    profile: "Payment Profile",
                    accountDetails: {
                        details: "Account Details",
                        bankName: "Bank name",
                        directoryId: "Directory ID",
                        displayAccountNumber: "Account number",
                        displayRoutingNumber: "Routing number",
                    },
                    transactionDetails: {
                        details: "Transaction Details",
                        averageTransactionValue: "Average transaction value",
                        maximumTransactionValue: "Maximum transaction value",
                        monthlyAverageReturnsNumber: "Monthly average returns",
                        monthlyProjectedVolume: "Monthly projected volume",
                        monthlyTransactionsNumber: "Monthly transactions number",
                    }
                },
                ownerDetails: {
                    title: "Title",
                    details: "Owner Details",
                    firstName: "First name",
                    lastName: "Last name",
                    dateOfBirth: "Date of birth",
                    email: "Email",
                    displaySsn: "SSN",
                    driverLicense: {
                        details: "Driver License",
                        displayLicenseNumber: "License number",
                        expiryDate: "Expiry date",
                        issuingDate: "Issuing date",
                        issuingState: "Issuing state",
                    },
                    responsible: "Responsible officer same as owner",
                    ownershipPercent: "Ownership percent",
                },
                responsibleOfficerDetails: {
                    title: "Title",
                    details: "Responsible Officer Details",
                    firstName: "First name",
                    lastName: "Last name",
                    dateOfBirth: "Date of birth",
                    email: "Email",
                    displaySsn: "SSN",
                    driverLicense: {
                        details: "Driver License",
                        displayLicenseNumber: "License number",
                        expiryDate: "Expiry date",
                        issuingDate: "Issuing date",
                        issuingState: "Issuing state",
                    },
                },
                invoicingDetails: {
                    details: "Invoicing Details",
                    emptyTitle: "T & C",
                    invoicePrefix: "Invoice prefix",
                    invoicingAddress: {
                        address: "Invoicing Address",
                        addressLineOne: "Address line one",
                        addressLineTwo: "Address line two",
                        city: "City",
                        addressState: "State",
                        zipcode: "Zipcode"
                    },
                    termsAndConditionsDesc: "Terms and conditions description"
                },
                cpm: {
                    details: "Consumer Payment Methods",
                    eligible: "Eligible",
                    enabled: "Enabled",
                    paymentMethod: "Payment Method",
                    paymentProcessor: "Payment Processor",
                    accountOnboardingMethods: "Account Onboarding Methods",
                    serviceFeeStructure: "Service Fee Structure",
                    percentMax: "Service Fee: Percent Max",
                    costFixed: "Service Fee: Fixed Cost",
                    paymentMethodStatus: "Status",
                },
                merchantKey: {
                    label: "Merchant Keys",
                    cardKnox: {
                        label: "Card Knox",
                        xKey: "X-Key",
                        onboardingSubmitted: "Onboarding Submitted",
                        paymentSiteUrl: "Payment Site URL",
                        processor: "Processor",
                        dateUpdated: "Date Updated",
                        agreementToken: "Agreement Token",
                        appId: "App ID",
                        refNum: "Reference Number",
                        errorCode: "Error Code",
                        onboardingErrorMessage: "CK Onboarding Error Message",
                        errorMessage: "Error Message",
                    }
                }
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        directory: {
            name: "Directory |||| Directory List",
            fields: {
                name: "Directory List",
                save: "Save",
                deactivate: "Delete Directory",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity & Status",
                features: "Features",
                status: "Business State",
                businessProfile: "Business Profile",
            },
            features: {
                payout: "Enable/Disable Payout",
                cardknox : "Enable/Disable CC by CardKnox",
                receipt: "Enable/Disable TID in Receipt",
            },
            page: {
                directoryStatus: "Directory Status",
                businessId: "Business ID",
                consumerMobileNumber: "Consumer Mobile Number",
                createdAt: "Record Created At",
                lastUpdatedAt: "Record Updated At",
                directoryType: "Directory Type",
                delete: "Delete Directory",
                paymentProfile: {
                    profile: "Directory Information",
                    accountDetails: {
                        details: "Account Details",
                        bankName: "Bank name",
                        directoryId: "Directory ID",
                        displayAccountNumber: "Account number",
                        displayRoutingNumber: "Routing number",
                    },
                },
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        payout: {
            name: "Payouts |||| Payouts",
            receivers_name: "Receivers |||| Receivers",
            fields: {
                commands: "Orders",
                name: "Payout List",
                owner_name: "Business Owner Name",
                save: "Save",
                submit: "Submit Payout to ACH",
            },
            filters: {
                last_visited: "Last visited",
            },
            fieldGroups: {
                identity: "Identity & Status",
                stateTimeline: "Status Time Line (PST)",
                status: "Payout State",
                payoutRequest: "Payout Request",
                businessInformation: "Business Information",
                receiverInformation: "Receiver Information",
                payoutOtherInfo: "Payout Other Information",
                l2pResponseData: "L2P response Data",
            },
            page: {
                id: "ID",
                payoutState: "Payout state",
                payoutId: "Payout ID",
                businessId: "Business ID",
                userId: "User ID",
                receiverMobileNumber: "Receiver Mobile Number",
                invoiceNumber: "Invoice Number",
                createdAt: "Created at",
                notificationSentDate: "Notification sent date",
                timeToLive: "Time to live",
                stateTimeline: "State time line",
                businessInformation: {
                    firstName: "First name",
                    lastName: "Last name",
                    businessEmail: "Business email",
                    businessLegalName: "Business legal name",
                    clientId: "Client ID",
                    accountInformation: {
                        details: "Business Account Details",
                        businessDirectoryId: "Business directory ID",
                        businessDisplayAccountNumber: "Business display account number",
                        businessBankName: "Business bank name",
                    },
                    businessAddress: {
                        address: "Business Address",
                        addressLineOne: "Address line one",
                        addressLineTwo: "Address line two",
                        city: "City",
                        addressState: "State",
                        zipcode: "Zipcode"
                    },
                    logo: {
                        details: "Logo",
                        logoAvailable: "Logo available",
                        displayLogoUrl: "Logo display URL",
                    },
                },
                receiverInformation: {
                    details: "Receiver Identity",
                    receiverMobileNumber: "Receiver mobile number",
                    email: "Email",
                    externalId: "External ID",
                    firstName: "First name",
                    lastName: "Last name",
                    accountInformation: {
                        details: "Receiver Account Details",
                        receiverDirectoryId: "Receiver directory ID",
                        receiverDisplayAccountNumber: "Receiver display account number",
                        receiverBankName: "Receiver bank name",
                        institutionLogo: "Institution logo",
                    },
                    identity: "Identity & Status",
                    receiverStatus: "Receiver Status",
                    businessId: "Business ID",
                    userId: "User ID",
                    receiverId: "Receiver ID",
                    createdAt: "Created at",
                    profile: "Receiver Profile",
                    resendVerify: "Resend Verification",
                    resendSMS: "Resend SMS?",
                    save: "Save",
                    resend: "Resend Verification",
                    referencePin: "Reference PIN",
                    linkStatus: "Account link status",
                    lastUpdatedAt: "Last updated at",
                    receiverAddress: {
                        address: "Receiver Address",
                        addressLineOne: "Address line one",
                        addressLineTwo: "Address line two",
                        city: "City",
                        addressState: "State",
                        zipcode: "Zipcode"
                    },
                },
                payoutRequest: {
                    payoutDescription: "Payout description",
                    serviceDate: "Service date",
                    serviceAmount: "Service amount",
                    taxPercentage: "Tax percentage",
                    totalAmount: "Total amount",
                    referenceInvoiceNumber: "Reference invoice number",
                    invoiceId: "Invoice ID",
                    invoiceDocAvailable: "Invoice doc available",
                    invoiceDocUrl: "Invoice doc URL",
                },
                payoutOtherInfo: {
                    resendCount: "Resend count",
                },
                l2pResponseData: {
                    transactionRsp: {
                        contactProfileId: "Contact profile ID",
                        transPymtId: "Transaction payment ID",
                        accountMask: "Account mask",
                        transId: "Transaction ID",
                        paymentProfileId: "Payment profile ID",
                        accountType: "Account type",
                        status: {
                            responseCode: "Response code",
                        },
                    },
                },
            },
            errors: {
                password_mismatch: "The password confirmation is not the same as the password.",
            },
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    taxes: 'Tax',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                address: 'Address',
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                nb_items: 'Nb Items',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
            section: {
                order: 'Order',
                customer: 'Customer',
                shipping_address: 'Shipping Address',
                items: 'Items',
                total: 'Totals',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                sales: 'Sales',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
            filters: {
                categories: 'Categories',
                stock: 'Stock',
                no_stock: 'Out of stock',
                low_stock: '1 - 9 items',
                average_stock: '10 - 49 items',
                enough_stock: '50 items & more',
                sales: 'Sales',
                best_sellers: 'Best sellers',
                average_sellers: 'Average',
                low_sellers: 'Low',
                never_sold: 'Never sold',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                businesses: 'Business List',
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                draft: 'Draft',
                pending: 'Pending',
                approved: 'Approved',
            },
        },
    },
};

export default customEnglishMessages;
