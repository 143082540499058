import * as React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';

import {Business} from '../types';
import {Avatar} from "@mui/material";

const BusinessLogoField = (props: FieldProps<Business>) => {
    const record = useRecordContext<Business>();
    if (!record) {
        return null;
    }
    return (
        <Avatar src={`${record.businessProfile.logoUrl}?size=64x64`}/>
    );
};

BusinessLogoField.defaultProps = {
    source: "logo",
    label: 'Logo'
};

export default BusinessLogoField;
