import * as React from "react";
import {DatagridConfigurable, DateField, List, NumberField, ReferenceField, TextField,} from "react-admin";
import {Theme, useMediaQuery} from "@mui/material";
import {transactionFilters, TransactionListActions} from "../transactions/TransactionList";
import TransactionStatusField from "../transactions/TransactionStatusField";
import MobileGrid from "../transactions/MobileGrid";


const RelatedTransactionList = () => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    return (
        <List
            filterDefaultValues={{status: 'Paid'}}
            sort={{field: 'createdAt', order: 'DESC'}}
            perPage={50}
            filters={transactionFilters}
            actions={<TransactionListActions/>}
            title="Related Transactions"
        >
            {isXSmall ? (
                <MobileGrid/>
            ) : (
                <>
                    {(
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <TextField source="transactionId" label="transaction ID"/>
                            <DateField source="paidAt" label="Paid Date" showTime/>
                            <TransactionStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <TextField source="consumerMobileNumber" label="Customer Mobile Number"/>
                            <TextField source="customerName" label="Customer Name"/>
                            <DateField source="notificationSentDate" label="Notification Sent Date" showTime/>
                            <TextField source="invoiceNumber" label="Invoice Number"/>
                            <NumberField
                                source="totalAmount"
                                label="Total Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                        </DatagridConfigurable>
                    )}
                </>
            )}
        </List>
    );
}

export default RelatedTransactionList;
