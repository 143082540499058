import TransactionIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import TransactionList from './TransactionList';
import TransactionEdit from './TransactionEdit';

export default {
    list: TransactionList,
    edit: TransactionEdit,
    TransactionIcon: KeyboardArrowRightIcon,
};
