import * as React from "react";
import {useRecordContext} from "react-admin";
import {Billing} from "../types";
import {blue, deepOrange, deepPurple} from "@mui/material/colors";
import {Typography} from "@mui/material";

const BillingPeriodField = () => {
    const record = useRecordContext<Billing>();
    return record ? (
        <Typography flex="1" sx={{color: getPeriodColor(record?.billingState), fontSize: 14}}>
            {record.sk.slice(7)}
        </Typography>
    ) : null;
};

BillingPeriodField.defaultProps = {
    source: 'sk',
    label: 'Billing Period',
};

function getPeriodColor(state: string | undefined) {
    if (state) {
        return blue["500"];
    }

    // fallback
    return "#000000";
}

export default BillingPeriodField;
