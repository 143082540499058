import React, { CSSProperties, useMemo } from "react";
import { useGetList } from "react-admin";
import { Theme, useMediaQuery } from "@mui/material";
import { startOfDay, subDays } from "date-fns";

import Welcome from "./Welcome";

import { Payout, Transaction } from "../types";
import MonthlyTransactionAmount from "./MonthlyTransactionAmount";
import NbNewTransactions from "./NbNewTransactions";
import PendingTransactions from "./PendingTransactions";
import TransactionChart from "./TransactionChart";
import NewBusinesses from "./NewBusinesses";
import MonthlyPayoutAmount from "./MonthlyPayoutAmount";
import NbNewPayouts from "./NbNewPayouts";
import PayoutChart from "./PayoutChart";
import AllTransactionAmount from "./AllTransactionAmount";
import AllPayoutAmount from "./AllPayoutAmount";

interface TransactionStats {
  transactionAmount: number;
  nbNewTransactions: number;
  pendingTransactions: Transaction[];
}

interface PayoutStats {
  payoutAmount: number;
  nbNewPayouts: number;
  pendingPayouts: Payout[];
}

interface State {
    nbNewTransactions?: number;
    pendingTransactions?: Transaction[];
    recentTransactions?: Transaction[];
    transactionAmount?: string;
}

interface PayoutState {
  nbNewPayouts?: number;
  pendingPayouts?: Payout[];
  recentPayouts?: Payout[];
  payoutAmount?: string;
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
    const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);
    const begin = useMemo(() => subDays(startOfDay(new Date("2022-01")), 30), []);

    const { data: allTransactions } = useGetList<Transaction>("transactions", {
        filter: { date_gte: begin.toISOString() },
        sort: { field: "date", order: "DESC" },
        pagination: { page: 1, perPage: 50 },
    });

  const { data: transactions } = useGetList<Transaction>("transactions", {
    filter: { date_gte: aMonthAgo.toISOString() },
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 50 },
  });

  const { data: payouts } = useGetList<Payout>("payouts", {
    filter: { date_gte: aMonthAgo.toISOString() },
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 50 },
  });

    const { data: allPayouts } = useGetList<Payout>("payouts", {
        filter: { date_gte: begin.toISOString() },
        sort: { field: "date", order: "DESC" },
        pagination: { page: 1, perPage: 50 },
    });

    const aggregation = useMemo<State>(() => {
        if (!transactions) return {};
        const aggregations = transactions
            .filter((transaction) => transaction.transactionState !== "cancelled")
            .reduce(
                (stats: TransactionStats, transaction) => {
                    if (
                        transaction.transactionState === "Paid" ||
                        transaction.transactionState === "Processed" ||
                        transaction.transactionState === "Approved" ||
                        transaction.transactionState === "Submitted"
                    ) {
                        stats.transactionAmount += transaction.totalAmount;
                        stats.nbNewTransactions++;
                    }
                    if (
                        transaction.transactionState === "Sent" ||
                        transaction.transactionState === "Resent"
                    ) {
                        stats.pendingTransactions.push(transaction);
                    }
                    return stats;
                },
                {
                    transactionAmount: 0,
                    nbNewTransactions: 0,
                    pendingTransactions: [],
                }
            );
        return {
            recentTransactions: transactions,
            transactionAmount: aggregations.transactionAmount.toLocaleString(
                undefined,
                {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }
            ),
            nbNewTransactions: aggregations.nbNewTransactions,
            pendingTransactions: aggregations.pendingTransactions,
        };
    }, [transactions]);

  const allAggregation = useMemo<State>(() => {
    if (!allTransactions) return {};
    const allAggregations = allTransactions
      .filter((transaction) => transaction.transactionState !== "cancelled")
      .reduce(
        (stats: TransactionStats, transaction) => {
          if (
            transaction.transactionState === "Paid" ||
            transaction.transactionState === "Processed" ||
            transaction.transactionState === "Approved" ||
            transaction.transactionState === "Submitted"
          ) {
            stats.transactionAmount += transaction.totalAmount;
            stats.nbNewTransactions++;
          }
          if (
            transaction.transactionState === "Sent" ||
            transaction.transactionState === "Resent"
          ) {
            stats.pendingTransactions.push(transaction);
          }
          return stats;
        },
        {
          transactionAmount: 0,
          nbNewTransactions: 0,
          pendingTransactions: [],
        }
      );
    return {
      recentTransactions: allTransactions,
      transactionAmount: allAggregations.transactionAmount.toLocaleString(
        undefined,
        {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      ),
      nbNewTransactions: allAggregations.nbNewTransactions,
      pendingTransactions: allAggregations.pendingTransactions,
    };
  }, [allTransactions]);


  const payoutAggregation = useMemo<PayoutState>(() => {
    if (!payouts) return {};
    const payoutAggregations = payouts
      .filter((payout) => payout.payoutState !== "cancelled")
      .reduce(
        (payoutStats: PayoutStats, payout) => {
          if (
            payout.payoutState === "Paid" ||
            payout.payoutState === "Processed" ||
            payout.payoutState === "Accepted" ||
            payout.payoutState === "Submitted"
          ) {
            payoutStats.payoutAmount += payout.payoutRequest.totalAmount;
            payoutStats.nbNewPayouts++;
          }
          if (
            payout.payoutState === "Sent" ||
            payout.payoutState === "Resent"
          ) {
            payoutStats.pendingPayouts.push(payout);
          }
          return payoutStats;
        },
        {
          payoutAmount: 0,
          nbNewPayouts: 0,
          pendingPayouts: [],
        }
      );
    return {
      recentPayouts: payouts,
      payoutAmount: payoutAggregations.payoutAmount.toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      nbNewPayouts: payoutAggregations.nbNewPayouts,
      pendingTransactions: payoutAggregations.pendingPayouts,
    };
  }, [payouts]);

    const allPayoutAggregation = useMemo<PayoutState>(() => {
        if (!allPayouts) return {};
        const allPayoutAggregations = allPayouts
            .filter((payout) => payout.payoutState !== "cancelled")
            .reduce(
                (payoutStats: PayoutStats, payout) => {
                    if (
                        payout.payoutState === "Paid" ||
                        payout.payoutState === "Processed" ||
                        payout.payoutState === "Accepted" ||
                        payout.payoutState === "Submitted"
                    ) {
                        payoutStats.payoutAmount += payout.payoutRequest.totalAmount;
                        payoutStats.nbNewPayouts++;
                    }
                    if (
                        payout.payoutState === "Sent" ||
                        payout.payoutState === "Resent"
                    ) {
                        payoutStats.pendingPayouts.push(payout);
                    }
                    return payoutStats;
                },
                {
                    payoutAmount: 0,
                    nbNewPayouts: 0,
                    pendingPayouts: [],
                }
            );
        return {
            recentPayouts: allPayouts,
            payoutAmount: allPayoutAggregations.payoutAmount.toLocaleString(undefined, {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }),
            nbNewPayouts: allPayoutAggregations.nbNewPayouts,
            pendingTransactions: allPayoutAggregations.pendingPayouts,
        };
    }, [allPayouts]);

    const {
        nbNewTransactions:allNewTransactions,
        pendingTransactions:allPendingTransactions,
        transactionAmount:allTransactionAmount,
        recentTransactions:allRecentTransactions,
    } = allAggregation;

  const { nbNewTransactions, pendingTransactions, transactionAmount, recentTransactions, } = aggregation;

  const { nbNewPayouts, pendingPayouts, payoutAmount, recentPayouts } = payoutAggregation;

    const {
        nbNewPayouts:allNbNewPayouts,
        pendingPayouts:allPendingPayouts,
        payoutAmount:allPayoutAmount,
        recentPayouts:allRecentPayouts,
    } = allPayoutAggregation;

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <Welcome />
        <MonthlyTransactionAmount value={transactionAmount} />
        <VerticalSpacer />
        <NbNewTransactions value={nbNewTransactions} />
        <VerticalSpacer />
        <PendingTransactions transactions={pendingTransactions} />
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      <div style={styles.flex}>
        <MonthlyTransactionAmount value={transactionAmount} />
        <Spacer />
        <NbNewTransactions value={nbNewTransactions} />
      </div>
      <div style={styles.singleCol}>
        <TransactionChart transactions={recentTransactions} />
      </div>
      <div style={styles.singleCol}>
        <PendingTransactions transactions={pendingTransactions} />
      </div>
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <MonthlyTransactionAmount value={transactionAmount} />
            <Spacer />
            <NbNewTransactions value={nbNewTransactions} />
          </div>
          <div style={styles.singleCol}>
            <TransactionChart transactions={recentTransactions} />
          </div>
          <div style={styles.flex}>
            <MonthlyPayoutAmount value={payoutAmount} />
            <Spacer />
            <NbNewPayouts value={nbNewPayouts} />
          </div>
          <div style={styles.singleCol}>
            <PayoutChart payouts={recentPayouts} />
          </div>
        </div>
        <div style={styles.rightCol}>

            <div style={styles.rightCol}>
                <div style={styles.flex}>
                    <AllPayoutAmount value={allPayoutAmount} />
                    <Spacer />
                    <AllTransactionAmount value={allTransactionAmount} />
                </div>
            </div>

          <div style={styles.singleCol}>
            <div style={styles.flex}>
              <PendingTransactions transactions={pendingTransactions} />
              <Spacer />
              <NewBusinesses />
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Dashboard;
