import * as React from 'react';
import {DateTimeInput, Edit, SelectInput, SimpleForm, TextInput, useTranslate,} from 'react-admin';
import {Box, Grid, Typography} from '@mui/material';

import DirectoryEditToolbar from "./DirectoryEditToolbar";
import DirectoryNameField from "./DirectoryNameField";
import DirectoryTypeField from "./DirectoryTypeField";
import ACHSubmitField from "../transactions/ACHSubmitField";
import DirectoryDeleteField from "./DirectoryDeleteField";

const Separator = () => <Box pt="1em"/>;


const DirectoryEdit = () => {
    const translate = useTranslate();
    return (
        <Edit title={<DirectoryTitle/>}>
            <SimpleForm
                toolbar={<DirectoryEditToolbar/>}
            >
                <div>
                    <Grid container width={{xs: '100%', xl: 1400}} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.directory.page.paymentProfile.profile'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="directoryId"
                                        resource="directory"
                                        label='resources.directory.page.paymentProfile.accountDetails.directoryId'
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="accountInfo.bankName"
                                                resource="directory"
                                                fullWidth
                                                label='resources.directory.page.paymentProfile.accountDetails.bankName'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="accountInfo.displayRoutingNumber"
                                                resource="directory"
                                                fullWidth
                                                label='resources.directory.page.paymentProfile.accountDetails.displayRoutingNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={2}>
                                            <TextInput
                                                source="accountInfo.displayAccountNumber"
                                                resource="directory"
                                                fullWidth
                                                label='resources.directory.page.paymentProfile.accountDetails.displayAccountNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Separator/>
                            <Box mt="15em"/>
                            <DirectoryDeleteField/>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.directory.fieldGroups.identity'
                                )}
                            </Typography>
                            <div>
                                <SelectInput
                                    resource="directory"
                                    source="directoryStatus"
                                    helperText={false}
                                    label='resources.directory.page.directoryStatus'
                                    choices={[
                                        {
                                            id: 'active',
                                            name: 'active',
                                        },
                                        {
                                            id: 'inactive',
                                            name: 'inactive',
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="businessId"
                                    source="businessId"
                                    resource="directory"
                                    fullWidth
                                    label='resources.directory.page.businessId'
                                    disabled={true}
                                    helperText={false}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="consumerMobileNumber"
                                    source="consumerMobileNumber"
                                    resource="directory"
                                    fullWidth
                                    label='resources.directory.page.consumerMobileNumber'
                                    disabled={true}
                                    helperText={false}
                                />
                            </div>
                            <div>
                                <DateTimeInput
                                    source="createdAt"
                                    resource="directory"
                                    label='resources.directory.page.createdAt'
                                    helperText={false}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <DateTimeInput
                                    source="lastUpdatedAt"
                                    resource="directory"
                                    label='resources.directory.page.lastUpdatedAt'
                                    helperText={false}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <DirectoryTypeField/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

const DirectoryTitle = () => <DirectoryNameField/>;

export default DirectoryEdit;
