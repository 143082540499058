import * as React from "react";
import {Fragment, useCallback} from "react";
import {
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    SelectColumnsButton,
    SelectInput,
    TopToolbar,
    useListContext,
} from "react-admin";
import {Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";

import RevenueStatusField from "./RevenueStatusField";
import RevenuePeriodField from "./RevenuePeriodField";
import LinkToRelatedTransactions from "./LinkToRelatedTransactions";

const TransactionListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
);

const RevenueList = () => (
    <List
        filterDefaultValues={{status: 'Paid'}}
        sort={{field: 'createdAt', order: 'DESC'}}
        perPage={50}
        filters={revenueFilters}
        actions={<TransactionListActions/>}
    >
        <TabbedDataGrid/>
    </List>
);

const revenueFilters = [
    <DateInput source="lastUpdatedAt"/>,
    <DateInput source="createdAt"/>,
    <SelectInput
        source="revenueState"
        choices={[
            {
                id: 'Draft',
                name: 'Draft',
            },
            {
                id: 'InProgress',
                name: 'InProgress',
            },
            {
                id: 'Paid',
                name: 'Paid',
            },
        ]
        }/>,
];

const tabs = [
    {id: 'Paid', name: 'Paid'},
    {id: 'InProgress', name: 'InProgress'},
    {id: 'Draft', name: 'Draft'},
];

const TabbedDataGrid = () => {
    const revenueListContext = useListContext();

    if (!Object.keys(revenueListContext?.filterValues)?.length) { // fix a bug with remove filter
        revenueListContext.filterValues["status"] = "Paid";
    }


    const {filterValues, setFilters, displayedFilters} = revenueListContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                {...filterValues, status: value},
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.name,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            {
                <>
                    {filterValues.status === 'Draft' && (
                        <DatagridConfigurable
                            bulkActionButtons={false}
                        >
                            <RevenuePeriodField/>
                            <RevenueStatusField/>
                            <DateField source="startDate" label="Period Start Date (UTC)" showTime
                                       options={{timeZone: 'UTC'}}/>
                            <DateField source="endDate" label="Period End Date (UTC)" showTime
                                       options={{timeZone: 'UTC'}}/>
                            <LinkToRelatedTransactions/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'InProgress' && (
                        <DatagridConfigurable
                            bulkActionButtons={false}
                        >
                            <RevenuePeriodField/>
                            <RevenueStatusField/>
                            <DateField source="startDate" label="Period Start Date (UTC)" showTime
                                       options={{timeZone: 'UTC'}}/>
                            <DateField source="endDate" label="Period End Date (UTC)" showTime
                                       options={{timeZone: 'UTC'}}/>
                            <LinkToRelatedTransactions/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Paid' && (
                        <DatagridConfigurable
                            bulkActionButtons={false}
                        >
                            <RevenuePeriodField/>
                            <RevenueStatusField/>
                            <DateField source="startDate" label="Period Start Date (UTC)" showTime
                                       options={{timeZone: 'UTC'}}/>
                            <DateField source="endDate" label="Period End Date (UTC)" showTime
                                       options={{timeZone: 'UTC'}}/>
                            <LinkToRelatedTransactions/>
                        </DatagridConfigurable>
                    )}
                </>
            }
        </Fragment>
    );
};

export default RevenueList;
