import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import BillingList from './BillingList';
import BillingEdit from './BillingEdit';

export default {
    list: BillingList,
    edit: BillingEdit,
    TransactionIcon: KeyboardArrowRightIcon,
};
