import * as React from 'react';
import {Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {stringify} from 'query-string';
import {Revenue} from "../types";
import {NumberField, useRecordContext} from "react-admin";

const LinkToRelatedTransactions = () => {
    const record = useRecordContext<Revenue>();
    if (!record) return null;
    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/relatedTransactions',
                search: stringify({
                    filter: JSON.stringify({startDate: record.startDate}),
                }),
            }}
            sx={{display: 'inline-flex', alignItems: 'center'}}
        >
            <NumberField
                source="totalPayinAmount"
                label="Transaction Volume"
                options={{
                    style: 'currency',
                    currency: 'USD',
                }}
                sx={{fontWeight: 'bold'}}
            />
        </Button>
    );
};

LinkToRelatedTransactions.defaultProps = {
    source: 'totalPayinAmount',
    label: 'Transaction Volume',
};

export default LinkToRelatedTransactions;
