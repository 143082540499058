import * as React from 'react';
import { Link, FieldProps, useRecordContext } from 'react-admin';

import FullNameField from './FullNameField';
import {Business} from '../types';

const BusinessOwnerNameField = (props: FieldProps<Business>) => {
    const record = useRecordContext<Business>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/businesses/${record.id}`}>
            <FullNameField />
        </Link>
    );
};

BusinessOwnerNameField.defaultProps = {
    source: 'business_id',
    label: 'Business Owner'
};

export default BusinessOwnerNameField;
