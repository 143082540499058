import * as React from 'react';
import {BooleanInput, FieldProps, useRecordContext} from 'react-admin';

import {Transaction} from '../types';
import {Box, Typography} from "@mui/material";

const ACHSubmitField = (props: FieldProps<Transaction>) => {
    const record = useRecordContext<Transaction>();

    if (record.transactionState !== 'Approved') {
        return null;
    }

    return (
        <Box display={{xs: 'block', sm: 'flex'}}>
            <Box flex={1} >
                <BooleanInput
                    row={true}
                    resource="transactions"
                    source="sendACH"
                    label='resources.r2p.page.paymentRequest.submitACH'
                    helperText={false}
                />
            </Box>
        </Box>
    );
};

ACHSubmitField.defaultProps = {
    source: 'business_id',
    label: 'Business Legal Name'
};

export default ACHSubmitField;

