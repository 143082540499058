import TransactionIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import BusinessList from "./BusinessList";
import BusinessEdit from "./BusinessEdit";

export default {
    list: BusinessList,
    edit: BusinessEdit,
    TransactionIcon: KeyboardArrowRightIcon,
};
