import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import DirectoryList from "./DirectoryList";
import DirectoryEdit from "./DirectoryEdit";

export default {
    list: DirectoryList,
    edit: DirectoryEdit,
    TransactionIcon: KeyboardArrowRightIcon,
};
