import { Auth } from '@aws-amplify/auth';
import config from './cognito';
import { createContext, useContext } from 'react';

Auth.configure(config.Auth);
const AuthContext = createContext({});

export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {

        // connect to cognito
        return Auth.signIn(username, password).then(
            user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                    Auth.completeNewPassword(
                       user,               // the Cognito User Object
                       password       // the new password
                    ).then(user => {
                        // at this time the user is logged in if no MFA required
                        console.log(user);
                        console.log(user.username);
                        console.log('setting local storage: username', username);
                        localStorage.setItem('username', username);
                        localStorage.setItem('user', user);
                        return {
                            status: true,
                            user,
                        };
                    }).catch(e => {
                        console.log(e);
                    });
                } else {
                    user.getSession((err, user) => console.log(user));
                    if (user) {
                        console.log(user);
                        console.log(user.username);
                        console.log('setting local storage: username', username);
                        localStorage.setItem('username', username);
                        localStorage.setItem('user', user);
                        return {
                            status: true,
                            user,
                        };
                    } else {
                        console.log('sbill...removing local storage: username');
                        localStorage.removeItem('username');
                        localStorage.removeItem('user');
                    }
                }
            },
            error => {
                console.log(
                    'sbill adminapp...removing local storage: username'
                );
                localStorage.removeItem('username');
                localStorage.removeItem('user');
                return {
                    status: false,
                    error,
                };
            }
        );
        /*return Promise.resolve();*/
    },
    // called when the user clicks on the logout button
    logout: () => {
        return Auth.signOut().then(() => {
            localStorage.removeItem('username');
            localStorage.removeItem('user');
        });
        // return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        if (status === undefined) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        console.log("inside checkAuth");
        return localStorage.getItem('username')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export const useAuth = () => useContext(AuthContext);
