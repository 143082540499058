import * as React from "react";
import {Fragment, useCallback} from "react";
import {
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    EmailField,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectColumnsButton,
    SelectInput,
    TextField,
    TopToolbar,
    useListContext,
} from "react-admin";
import {Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";

import MobileGrid from "./MobileGrid";
import BusinessLegalNameField from "./BusinessLegalNameField";
import BusinessOwnerNameField from "./BusinessOwnerNameField";
import BusinessStatusField from "./BusinessStatusField";
import DisplayAddressField from "./DisplayAddressField";
import BusinessBillingLinkField from "./BusinessBillingLinkField";
import BusinessLogoField from "./BusinessLogoField";


const BusinessListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
);


const businessFilters = [
    <SearchInput source="businessProfile.businessLegalName" alwaysOn/>,
    <DateInput source="createdAt"/>,
    <SelectInput
        source="businessState"
        choices={[
            {
                id: 'Draft',
                name: 'Draft',
            },
            {
                id: 'Pending',
                name: 'Pending',
            },
            {
                id: 'Approved',
                name: 'Approved',
            },
            {
                id: 'Disabled',
                name: 'Disabled',
            },
        ]
        }/>,
    <SelectInput
        source="template"
        choices={[
            {
                id: 'Student',
                name: 'Student',
            },
            {
                id: 'MentalHealth',
                name: 'Mental Health',
            },
            {
                id: 'Default',
                name: 'Default',
            },
        ]
        }/>,
    <SelectInput
        source="feature"
        choices={[
            {
                id: 'Payout',
                name: 'Payout',
            },
            {
                id: 'QRCodePayIn',
                name: 'QR Code Pay-In',
            },
            {
                id: 'QRCodePayOut',
                name: 'QR Code PayOut',
            },
        ]
        }/>,
];


const businessTabs = [
    {id: 'Approved', name: 'Approved'},
    {id: 'Pending', name: 'Pending'},
    {id: 'Draft', name: 'Draft'},
];

const BusinessList = () => (
    <List
        filterDefaultValues={{status: 'Approved'}}
        sort={{field: 'createdAt', order: 'DESC'}}
        perPage={50}
        filters={businessFilters}
        actions={<BusinessListActions/>}
    >
        <TabbedDataGrid/>
    </List>
);

const TabbedDataGrid = () => {
    const businessListContext = useListContext();

    if (!Object.keys(businessListContext?.filterValues)?.length) { // fix a bug with remove filter
        businessListContext.filterValues["status"] = "Approved";
    }

    const {filterValues, setFilters, displayedFilters} = businessListContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {

            setFilters &&
            setFilters(
                {...filterValues, status: value},
                displayedFilters,
                true // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {businessTabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.name,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            {isXSmall ? (
                <MobileGrid/>
            ) : (
                <>
                    {filterValues.status === 'Approved' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BusinessLogoField/>
                            <BusinessLegalNameField/>
                            <TextField source="businessId" label="Business ID" sx={{color: 'purple'}}/>
                            <BusinessOwnerNameField/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <TextField source="clientId" label="Client ID"/>
                            <BusinessStatusField/>
                            <TextField source="businessProfile.businessDescription" label="Description"/>
                            <DisplayAddressField source="businessAddress" label="Business Address"/>
                            <EmailField source="ownerDetails.email" label="Owner's Email"/>
                            <TextField source="userId" label="User ID"/>
                            <BusinessBillingLinkField source="businessId" label="Billing Info"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Pending' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BusinessLogoField/>
                            <BusinessLegalNameField/>
                            <TextField source="businessId" label="Business ID" sx={{color: 'purple'}}/>
                            <BusinessOwnerNameField/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <TextField source="clientId" label="Client ID"/>
                            <BusinessStatusField/>
                            <TextField source="businessProfile.businessDescription" label="Description"/>
                            <DisplayAddressField source="businessAddress" label="Business Address"/>
                            <EmailField source="ownerDetails.email" label="Owner's Email"/>
                            <TextField source="userId" label="User ID"/>
                            <BusinessBillingLinkField source="businessId" label="Billing Info"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Draft' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BusinessLogoField/>
                            <BusinessLegalNameField/>
                            <TextField source="businessId" label="Business ID" sx={{color: 'purple'}}/>
                            <BusinessOwnerNameField/>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <TextField source="clientId" label="Client ID"/>
                            <BusinessStatusField/>
                            <TextField source="businessProfile.businessDescription" label="Description"/>
                            <DisplayAddressField source="businessAddress" label="Business Address"/>
                            <EmailField source="ownerDetails.email" label="Owner's Email"/>
                            <TextField source="userId" label="User ID"/>
                            <BusinessBillingLinkField/>
                        </DatagridConfigurable>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default BusinessList;
