import * as React from "react";
import {useRecordContext} from "react-admin";
import {Business} from "../types";
import {Link} from "@mui/material";
import {getStatusColor} from "../billingList/BillingStatusField";

const BusinessBillingLinkField = () => {
    const record = useRecordContext<Business>();
    const props = {textColor: getStatusColor("PDF"), addLabel: true, allowEmpty: true};
    const {...rest} = props;

    const uri = `/#/billing?displayedFilters={"businessId":true}&filter={"status":"Invoiced","businessId":"${record?.businessId}"}&order=DESC&page=1&perPage=50&sort=createdAt`;

    return (
        <Link
            href={uri}
            variant="body2"
            sx={{color: getStatusColor(record?.billingState)}}
        >
           billing
        </Link>
    );
};

BusinessBillingLinkField.defaultProps = {
    source: 'businessId',
    label: 'Billing Detail',
};


export default BusinessBillingLinkField;
