import * as React from "react";
import {
  blue,
  blueGrey,
  deepOrange,
  deepPurple,
  green,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import { TextField, useRecordContext } from "react-admin";
import {Business, Transaction} from "../types";

const BusinessStatusField = () => {
  const record = useRecordContext<Business>();
  return (
    <TextField
      record={record}
      source="businessState"
      sx={{ color: getStatusColor(record?.businessState) }}
    />
  );
};

BusinessStatusField.defaultProps = {
  source: "businessState",
  label: "Business State",
};

function getStatusColor(state: string | undefined) {
  if (state) {
    return deepPurple["900"];
  }
  /*if (state.toLowerCase().includes("expired")) {
    return red["500"];
  }
  if (state.toLowerCase().includes("sent")) {
    return blue["500"];
  }
  if (state.toLowerCase().includes("resent")) {
    return blueGrey["500"];
  }
  if (state.toLowerCase().includes("cancelled")) {
    return deepPurple["500"];
  }
  if (state.toLowerCase().includes("withdrawn")) {
    return yellow["800"];
  }
  if (state.toLowerCase().includes("paid")) {
    return "#000000";
  }
  if (state.toLowerCase().includes("submitted")) {
    return green["500"];
  }
  if (state.toLowerCase().includes("failed")) {
    return red["2000"];
  }
  if (state.toLowerCase().includes("approved")) {
    return deepOrange["500"];
  }
  if (state.toLowerCase().includes("rejected")) {
    return teal["500"];
  }
  if (state.toLowerCase().includes("pending")) {
    return blue["800"];
  }
  if (state.toLowerCase().includes("draft")) {
    return deepOrange["800"];
  }*/

  // fallback
  return "#000000";
}

export default BusinessStatusField;
