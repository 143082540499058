import * as React from "react";
import {Fragment, useCallback} from "react";
import {
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List, ReferenceField,
    SearchInput,
    SelectColumnsButton,
    SelectInput,
    TextField,
    TopToolbar,
    useListContext,
} from "react-admin";
import {Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";

import MobileGrid from "./MobileGrid";
import DirectoryStatusField from "./DirectoryStatusField";
import InstitutionLogoField from "./InstitutionLogoField";


const DirectoryListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
);


const directoryFilters = [
    <SearchInput source="consumerMobileNumber" alwaysOn/>,
    <DateInput source="createdAt"/>,
   /* <SelectInput
        source="directoryStatus"
        choices={[
            {
                id: 'active',
                name: 'active',
            },
            {
                id: 'inactive',
                name: 'inactive',
            },
        ]
        }/>,*/
    <SelectInput
        source="type"
        choices={[
            {
                id: 'Business',
                name: 'Business',
            },
            {
                id: 'MobileNumber',
                name: 'Mobile Number',
            },
        ]
        }/>,
];


const directoryTabs = [
    {id: 'active', name: 'active'},
    {id: 'inactive', name: 'inactive'},
];

const DirectoryList = () => (
    <List
        filterDefaultValues={{status: 'active'}}
        sort={{field: 'createdAt', order: 'DESC'}}
        perPage={50}
        filters={directoryFilters}
        actions={<DirectoryListActions/>}
    >
        <DirectoryTabbedDataGrid/>
    </List>
);

const DirectoryTabbedDataGrid = () => {
    const directoryListContext = useListContext();

    if (!Object.keys(directoryListContext?.filterValues)?.length) { // fix a bug with remove filter
        directoryListContext.filterValues["status"] = "active";
    }

    const {filterValues, setFilters, displayedFilters} = directoryListContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {

            setFilters &&
            setFilters(
                {...filterValues, status: value},
                displayedFilters,
                true // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {directoryTabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.name,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            {isXSmall ? (
                <MobileGrid/>
            ) : (
                <>
                    {filterValues.status === 'active' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <InstitutionLogoField/>
                            <TextField source="directoryId" label="Directory ID" sx={{color: 'purple'}}/>
                            <TextField source="accountInfo.bankName" label="Bank Name"/>
                            <TextField source="accountInfo.displayAccountNumber" label="Display Account Number"/>
                            <TextField source="accountInfo.displayRoutingNumber" label="Display Routing Number"/>
                            <TextField source="consumerMobileNumber" label="Consumer Mobile Number"/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <DateField source="lastUpdatedAt" label="Last Updated At" showTime/>
                            <TextField source="accountInfo.achInfo.serviceName" label="Service Name"/>
                            <DirectoryStatusField/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'inactive' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <InstitutionLogoField/>
                            <TextField source="directoryId" label="Directory ID" sx={{color: 'purple'}}/>
                            <TextField source="accountInfo.bankName" label="Bank Name"/>
                            <TextField source="accountInfo.displayAccountNumber" label="Display Account Number"/>
                            <TextField source="accountInfo.displayRoutingNumber" label="Display Routing Number"/>
                            <TextField source="consumerMobileNumber" label="Consumer Mobile Number"/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="createdAt" label="Created Date" showTime/>
                            <DateField source="lastUpdatedAt" label="Last Updated At" showTime/>
                            <TextField source="accountInfo.achInfo.serviceName" label="Service Name"/>
                            <DirectoryStatusField/>
                        </DatagridConfigurable>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default DirectoryList;
