import * as React from "react";
import {memo} from "react";
import {FieldProps} from "react-admin";
import {Payout} from "../../types";
import {Typography} from "@mui/material";

interface Props extends FieldProps<Payout> {
    size?: string;
}

const PayoutIdField = (props: Props) => {
    const {record, size} = props;
    const text = "Payout Transaction ID:"
    return record ? (
        <Typography
            variant="body2"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            component="div"
            sx={{margin: '5px 0'}}
        >
            {text} {record.payoutId}
        </Typography>
    ) : null;
};

PayoutIdField.defaultProps = {
    source: 'payoutId',
    label: 'resources.r2p.fields.name',
};

export default memo<Props>(PayoutIdField);
