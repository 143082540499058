import * as React from 'react';
import {Fragment} from 'react';
import Toolbar from '@mui/material/Toolbar';

import {SaveButton, ToolbarProps, useNotify, useRecordContext, useRedirect,} from 'react-admin';
import {Review} from '../types';

const DirectoryEditToolbar = (props: ToolbarProps) => {
    const {resource} = props;
    const redirect = useRedirect();
    const notify = useNotify();

    const record = useRecordContext<Review>(props);

    if (!record) return null;
    return (
        <Toolbar
            sx={{
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'space-between',
                minHeight: {sm: 0},
                marginBottom: '1em',
            }}
        >
            <Fragment>
                <SaveButton
                    label="resources.directory.fields.save"
                    mutationOptions={{
                        onSuccess: () => {
                            notify('ra.notification.updated', {
                                type: 'info',
                                messageArgs: {smart_count: 1},
                                undoable: true,
                            });
                            redirect('list', 'directory');
                        },
                    }}
                    type="button"
                />
                <SaveButton
                    label="resources.directory.fields.deactivate"
                    mutationOptions={{
                        onSuccess: () => {
                            notify('ra.notification.updated', {
                                type: 'info',
                                messageArgs: {smart_count: 1},
                                undoable: true,
                            });
                            redirect('list', 'directory');
                        },
                    }}
                    type="button"
                />
            </Fragment>
        </Toolbar>
    );
};

export default DirectoryEditToolbar;
