import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';

import {DashboardMenuItem, MenuItemLink, MenuProps, useSidebarState, useTranslate,} from 'react-admin';
import SubMenu from './SubMenu';
import transactions from '../transactions';

type MenuName = 'menuR2P' | 'menuPayout' | 'menuUsers' | 'menuBilling' | 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({dense = false}: MenuProps) => {
    const [state, setState] = useState({
        menuUsers: true,
        menuR2P: true,
        menuPayout: true,
        menuBilling: true,
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem/>
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                name="pos.menu.users"
                icon={<transactions.TransactionIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/businesses"
                    state={{_scrollToTop: true}}
                    primaryText={translate(`resources.businesses.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<transactions.TransactionIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to="/directory"
                    state={{_scrollToTop: true}}
                    primaryText={translate(`resources.directory.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<transactions.TransactionIcon/>}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuR2P')}
                isOpen={state.menuR2P}
                name="pos.menu.r2p"
                icon={<transactions.TransactionIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/transactions"
                    state={{_scrollToTop: true}}
                    primaryText={translate(`resources.r2p.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<transactions.TransactionIcon/>}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuPayout')}
                isOpen={state.menuPayout}
                name="pos.menu.payout"
                icon={<transactions.TransactionIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/payouts"
                    state={{_scrollToTop: true}}
                    primaryText={translate(`resources.payout.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<transactions.TransactionIcon/>}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuBilling')}
                isOpen={state.menuBilling}
                name="pos.menu.billing"
                icon={<transactions.TransactionIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/revenue"
                    state={{_scrollToTop: true}}
                    primaryText={translate(`resources.billing.tpv`, {
                        smart_count: 2,
                    })}
                    leftIcon={<transactions.TransactionIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to="/billing"
                    state={{_scrollToTop: true}}
                    primaryText={translate(`resources.billing.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<transactions.TransactionIcon/>}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
