const mcc = [
	{id: "742", name: "VETERINARY SERVICES"},
	{id: "763", name: "AGRICULTURAL CO-OPERATIVES"},
	{id: "780", name: "HORTICULTURAL SERVICES"},
	{id: "1520", name: "GENERAL CONTRACTORS-RESIDENTIAL AND COMMERCIAL"},
	{id: "1711", name: "AIR CONDITIONING CONTRACTORS - SALES AND INSTALLATION"},
	{id: "1731", name: "ELECTRICAL CONTRACTORS"},
	{id: "1740", name: "INSULATION - CONTRACTORS"},
	{id: "1750", name: "CARPENTRY CONTRACTORS"},
	{id: "1761", name: "ROOFING - CONTRACTORS"},
	{id: "1771", name: "CONTRACTORS - CONCRETE WORK"},
	{id: "1799", name: "CONTRACTORS - SPECIAL TRADE, NOT ELSEWHERE CLASSIFIED"},
	{id: "2741", name: "MISCELLANEOUS PUBLISHING AND PRINTING"},
	{id: "2791", name: "TYPESETTING, PLATE MAKING, & RELATED SERVICES"},
	{id: "2842", name: "SPECIALTY CLEANING, POLISHING, AND SANITATION PREPARATIONS"},
	{id: "3000", name: "AIRLINES, AIR CARRIERS"},
	{
		id: "3300",
		name: "AUTOMOBILE RENTALS – REFER TO THE T&E SECTION FOR A COMPLETE LISTING OF MAJOR CAR RENTAL MERCHANTS"
	},
	{id: "3500", name: "HOTELS , MOTELS AND INNS"},
	{id: "4011", name: "FREIGHT – RAIL, TRAIN TRANSPORTATION"},
	{
		id: "4111",
		name: "LOCAL/SUBURBAN COMMUTER PASSENGER TRANSPORTATION - RAILROADS, FERIES, LOCAL WATER TRANSPORTATION"
	},
	{id: "4112", name: "PASSENGER RAILWAYS"},
	{id: "4119", name: "AMBULANCE SERVICES"},
	{id: "4121", name: "TAXICABS AND LIMOUSINES"},
	{id: "4131", name: "BUS LINES, INCLUDING CHARTERS, TOUR BUSES"},
	{id: "4214", name: "DELIVERY SERVICES - LOCAL"},
	{id: "4215", name: "COURIER SERVICES - AIR OR GROUND"},
	{id: "4225", name: "STORAGE"},
	{id: "4411", name: "CRUISE LINES"},
	{id: "4457", name: "BOAT RENTALS AND LEASES"},
	{id: "4468", name: "MARINAS, MARINE SERVICE, AND SUPPLIES"},
	{id: "4511", name: "AIRLINES, AIR CARRIERS ( NOT LISTED ELSEWHERE)"},
	{id: "4582", name: "AIRPORTS, AIRPORT TERMINALS"},
	{id: "4722", name: "CHARTER BUSES"},
	{id: "4723", name: "PACKAGE TOUR OPERATORS (FOR USE IN GERMANY ONLY)"},
	{id: "4784", name: "TOLL AND BRIDGE FEES"},
	{id: "4789", name: "TRANSPORTATION SERVICES, NOT ELSEWHERE CLASSIFIED)"},
	{id: "4812", name: "CELLULAR TELEPHONE EQUIPMENT"},
	{id: "4814", name: "CELLULAR TELEPHONE SERVICE"},
	{id: "4816", name: "COMPUTER NETWORK/INFORMATION SERVICES"},
	{id: "4821", name: "CABLEGRAMS"},
	{id: "4829", name: "MONEY ORDERS – WIRE TRANSFER"},
	{id: "4899", name: "CABLE AND OTHER PAY TELEVISION (PREVIOUSLY CABLE SERVICES)"},
	{id: "4900", name: "ELECTRIC, GAS, SANITARY AND WATER UTILITIES"},
	{id: "5013", name: "MOTOR VEHICLE SUPPLIES AND NEW PARTS"},
	{id: "5021", name: "OFFICE AND COMMERCIAL FURNITURE"},
	{id: "5039", name: "CONSTRUCTION MATERIALS, NOT ELSEWHERE CLASSIFIED"},
	{id: "5044", name: "OFFICE, PHOTOGRAPHIC, PHOTOCOPY, AND MICROFILM EQUIPMENT"},
	{id: "5045", name: "COMPUTERS, COMPUTER PERIPHERAL EQUIPMENT, SOFTWARE"},
	{id: "5046", name: "COMMERCIAL EQUIPMENT, NOT ELSEWHERE CLASSIFIED"},
	{id: "5047", name: "MEDICAL, DENTAL OPHTHALMIC, HOSPITAL EQUIPMENT AND SUPPLIES"},
	{id: "5051", name: "METAL SERVICE CENTERS AND OFFICES"},
	{id: "5065", name: "ELECTRICAL PARTS AND EQUIPMENT"},
	{id: "5072", name: "HARDWARE EQUIPMENT AND SUPPLIES"},
	{id: "5074", name: "PLUMBING AND HEATING EQUIPMENT AND SUPPLIES"},
	{id: "5085", name: "INDUSTRIAL SUPPLIES, NOT ELSEWHERE CLASSIFIED"},
	{id: "5094", name: "PRECIOUS STONES AND METALS, WATCHES AND JEWELRY"},
	{id: "5099", name: "DURABLE GOODS, NOT ELSEWHERE CLASSIFIED"},
	{id: "5111", name: "STATIONERY, OFFICE SUPPLIES, PRINTING, AND WRITING PAPER"},
	{id: "5122", name: "COSMETIC DISTRIBUTORS"},
	{id: "5131", name: "PIECE GOODS, NOTIONS, AND OTHER DRY GOODS"},
	{id: "5137", name: "MENS WOMENS AND CHILDRENS UNIFORMS AND COMMERCIAL CLOTHING"},
	{id: "5139", name: "COMMERCIAL FOOTWEAR"},
	{id: "5169", name: "CHEMICALS AND ALLIED PRODUCTS, NOT ELSEWHERE CLASSIFIED"},
	{id: "5172", name: "PETROLEUM AND PETROLEUM PRODUCTS"},
	{id: "5192", name: "BOOKS, PERIODICALS, AND NEWSPAPERS"},
	{id: "5193", name: "FLORISTS SUPPLIES, NURSERY STOCK AND FLOWERS"},
	{id: "5198", name: "PAINTS, VARNISHES, AND SUPPLIES"},
	{id: "5199", name: "NON-DURABLE GOODS, NOT ELSEWHERE CLASSIFIED"},
	{id: "5200", name: "HOME SUPPLY WAREHOUSE STORES"},
	{id: "5211", name: "LUMBER AND BUILDING MATERIALS STORES"},
	{id: "5231", name: "GLASS STORES"},
	{id: "5251", name: "HARDWARE STORES"},
	{id: "5261", name: "NURSERIES - LAWN AND GARDEN SUPPLY STORE"},
	{id: "5271", name: "MOBILE HOME DEALERS"},
	{id: "5300", name: "WHOLESALE CLUBS"},
	{id: "5309", name: "DUTY FREE STORE"},
	{id: "5310", name: "DISCOUNT STORES"},
	{id: "5311", name: "DEPARTMENT STORES"},
	{id: "5331", name: "VARIETY STORES"},
	{id: "5399", name: "MISC. GENERAL MERCHANDISE"},
	{id: "5411", name: "GROCERY STORES"},
	{id: "5422", name: "FREEZER AND LOCKER MEAT PROVISIONERS"},
	{id: "5441", name: "CANDY STORES"},
	{id: "5451", name: "DAIRY PRODUCTS STORES"},
	{id: "5462", name: "BAKERIES"},
	{id: "5499", name: "MISC. FOOD STORES - CONVENIENCE STORES AND SPECIALTY MARKETS"},
	{id: "5511", name: "CAR AND TRUCK DEALERS (NEW AND USED) SALES, SERVICE, REPAIRS, PARTS AND LEASING"},
	{id: "5521", name: "AUTOMOBILE AND TRUCK DEALERS (USED ONLY)"},
	{id: "5531", name: "AUTOMOBILE SUPPLY STORES"},
	{id: "5532", name: "AUTOMOTIVE TIRE STORES"},
	{id: "5533", name: "AUTOMOTIVE PARTS, ACCESSORIES STORES"},
	{id: "5541", name: "SERVICE STATIONS ( WITH OR WITHOUT ANCILLARY SERVICES)"},
	{id: "5542", name: "AUTOMATED FUEL DISPENSERS"},
	{id: "5551", name: "BOAT DEALERS"},
	{id: "5561", name: "RECREATIONAL AND UTILITY TRAILERS, CAMP DEALERS"},
	{id: "5571", name: "MOTORCYCLE DEALERS"},
	{id: "5592", name: "MOTOR HOME DEALERS"},
	{id: "5598", name: "SNOWMOBILE DEALERS"},
	{id: "5599", name: "AIRPLANE, AIRCRAFT DEALERS"},
	{id: "5611", name: "MENS AND BOYS CLOTHING AND ACCESSORIES STORES"},
	{id: "5621", name: "WOMENS READY-TO-WEAR STORES"},
	{id: "5631", name: "WOMENS ACCESSORY AND SPECIALTY SHOPS"},
	{id: "5641", name: "CHILDRENS AND INFANTS WEAR STORES"},
	{id: "5651", name: "FAMILY CLOTHING STORES"},
	{id: "5655", name: "SPORTS APPAREL, RIDING APPAREL STORES"},
	{id: "5661", name: "SHOE STORES"},
	{id: "5681", name: "FURRIERS AND FUR SHOPS"},
	{id: "5691", name: "MENS AND WOMENS CLOTHING STORES"},
	{id: "5697", name: "TAILORS, SEAMSTRESS, MENDING, AND ALTERATIONS"},
	{id: "5698", name: "WIG AND TOUPEE STORES"},
	{id: "5699", name: "MISCELLANEOUS APPAREL AND ACCESSORY SHOPS"},
	{id: "5712", name: "FURNITURE, HOME FURNISHINGS, AND EQUIPMENT STORES, EXCEPT APPLIANCES"},
	{id: "5713", name: "FLOOR COVERING STORES"},
	{id: "5714", name: "DRAPERY, WINDOW COVERING AND UPHOLSTERY STORES"},
	{id: "5718", name: "FIREPLACE, FIREPLACE SCREENS, AND ACCESSORIES STORES"},
	{id: "5719", name: "MISCELLANEOUS HOME FURNISHING SPECIALTY STORES"},
	{id: "5722", name: "HOUSEHOLD APPLIANCE STORES"},
	{id: "5732", name: "ELECTRONIC SALES"},
	{id: "5733", name: "MUSIC STORES, MUSICAL INSTRUMENTS, PIANO SHEET MUSIC"},
	{id: "5734", name: "COMPUTER SOFTWARE STORES"},
	{id: "5735", name: "RECORD SHOPS"},
	{id: "5811", name: "CATERERS"},
	{id: "5812", name: "EATING PLACES AND RESTAURANTS"},
	{
		id: "5813",
		name: "DRINKING PLACES (ALCOHOLIC BEVERAGES), BARS, TAVERNS, COCKTAIL LOUNGES, NIGHTCLUBS AND DISCOTHEQUES"
	},
	{id: "5814", name: "FAST FOOD RESTAURANTS"},
	{id: "5815", name: "DIGITAL MEDIA – BOOKS, MOVIES, MUSIC"},
	{id: "5816", name: "DIGITAL GAMES"},
	{id: "5817", name: "DIGITAL APPLICATIONS"},
	{id: "5818", name: "DIGITAL GOODS – LARGE DIGITAL GOODS MERCHANT"},
	{id: "5832", name: "ANTIQUE SHOPS - SALES, REPAIRS, AND RESTORATION SERVICES"},
	{id: "5912", name: "DRUG STORES, PHARMACIES"},
	{id: "5921", name: "PACKAGE STORES - BEER, WINE, AND LIQUOR"},
	{id: "5931", name: "USED MERCHANDISE AND SECONDHAND STORES"},
	{id: "5932", name: "ANTIQUE SHOPS – SALES, REPAIRS, AND RESTORATION SERVICES"},
	{id: "5933", name: "PAWN SHOPS AND SALVAGE YARDS"},
	{id: "5935", name: "WRECKING AND SALVAGE YARDS"},
	{id: "5937", name: "ANTIQUE REPRODUCTIONS"},
	{id: "5940", name: "BICYCLE SHOPS- SALES AND SERVICE"},
	{id: "5941", name: "SPORTING GOODS STORES"},
	{id: "5942", name: "BOOK STORES"},
	{id: "5943", name: "STATIONERY STORES, OFFICE AND SCHOOL SUPPLY STORES"},
	{id: "5944", name: "WATCH, CLOCK, JEWELRY, AND SILVERWARE STORES"},
	{id: "5945", name: "HOBBY, TOY, AND GAME SHOPS"},
	{id: "5946", name: "CAMERA AND PHOTOGRAPHIC SUPPLY STORES"},
	{id: "5947", name: "CARD SHOPS, GIFT, NOVELTY, AND SOUVENIR SHOPS"},
	{id: "5948", name: "LEATHER STORES"},
	{id: "5949", name: "SEWING, NEEDLE, FABRIC, AND PRICE GOODS STORES"},
	{id: "5950", name: "GLASSWARE/CRYSTAL STORES"},
	{id: "5960", name: "AUTOMOBILE INSURANCE – SALES (DIRECT MARKETING)"},
	{
		id: "5961",
		name: "MAIL ORDER HOUSES INCLUDING CATALOG ORDER STORES, BOOK/RECORD CLUBES (NO LONGER PERMITTED FOR U.S. ORIGINAL PRESENTMENTS)"
	},
	{id: "5962", name: "DIRECT MARKETING – TRAVEL-RELATED ARRANGEMENT SERVICES"},
	{id: "5963", name: "DOOR-TO-DOOR SALES"},
	{id: "5964", name: "CATALOG MERCHANT – DIRECT MARKETING"},
	{id: "5965", name: "CATALOG, RETAIL MERCHANT – DIRECT MARKETING"},
	{id: "5966", name: "DIRECT MARKETING – OUTBOUND TELEMARKETING MERCHANT"},
	{id: "5967", name: "AUDIO-TEXT MERCHANTS"},
	{id: "5968", name: "CONTINUITY/SUBSCRIPTION MERCHANT – DIRECT MARKETING"},
	{id: "5969", name: "CLASSIFIED ADS – NEWSPAPER (VIA DIRECT MARKETING)"},
	{id: "5970", name: "ARTISTS SUPPLY AND CRAFT SHOPS"},
	{id: "5971", name: "ART DEALERS AND GALLERIES"},
	{id: "5972", name: "STAMP AND COIN STORES - PHILATELIC AND NUMISMATIC SUPPLIES"},
	{id: "5973", name: "RELIGIOUS GOODS STORES"},
	{id: "5975", name: "HEARING AIDS - SALES, SERVICE, AND SUPPLY STORES"},
	{id: "5976", name: "ORTHOPEDIC GOODS PROSTHETIC DEVICES"},
	{id: "5977", name: "COSMETIC STORES"},
	{id: "5978", name: "TYPEWRITER STORES - SALES, RENTAL, SERVICE"},
	{id: "5983", name: "FUEL - FUEL OIL, WOOD, COAL, LIQUEFIED PETROLEUM"},
	{id: "5992", name: "FLORISTS"},
	{id: "5993", name: "CIGAR STORES AND STANDS"},
	{id: "5994", name: "NEWS DEALERS AND NEWSSTANDS"},
	{id: "5995", name: "PET SHOPS, PET FOODS, AND SUPPLIES STORES"},
	{id: "5996", name: "SWIMMING POOLS - SALES, SERVICE, AND SUPPLIES"},
	{id: "5997", name: "ELECTRIC RAZOR STORES - SALES AND SERVICE"},
	{id: "5998", name: "TENT AND AWNING SHOPS"},
	{id: "5999", name: "MISCELLANEOUS AND SPECIALTY RETAIL STORES"},
	{id: "6010", name: "FINANCIAL INSTITUTIONS - MANUAL CASH DISBURSEMENTS"},
	{id: "6011", name: "FINANCIAL INSTITUTIONS - MANUAL CASH DISBURSEMENTS"},
	{id: "6012", name: "FINANCIAL INSTITUTIONS - MERCHANDISE AND SERVICES"},
	{
		id: "6051",
		name: "NON-FINANCIAL INSTITUTIONS - FOREIGN CURRENCY, MONEY ORDERS (NOT WIRE TRANSFER) AND TRAVELERS CHEQUES"
	},
	{id: "6211", name: "SECURITY BROKERS/DEALERS"},
	{id: "6300", name: "INSURANCE SALES, UNDERWRITING, AND PREMIUMS"},
	{id: "6381", name: "INSURANCE PREMIUMS, (NO LONGER VALID FOR FIRST PRESENTMENT WORK)"},
	{id: "6399", name: "INSURANCE, NOT ELSEWHERE CLASSIFIED ( NO LONGER VALID FOR FIRST PRESENTMENT WORK)"},
	{id: "6513", name: "REAL ESTATE AGENTS AND MANAGERS - RENTALS"},
	{
		id: "7011",
		name: "LODGING - HOTELS, MOTELS, RESORTS, CENTRAL RESERVATION SERVICES (NOT ELSEWHERE CLASSIFIED)"
	},
	{id: "7012", name: "TIMESHARES"},
	{id: "7032", name: "SPORTING AND RECREATIONAL CAMPS"},
	{id: "7033", name: "TRAILER PARKS AND CAMP GROUNDS"},
	{id: "7210", name: "LAUNDRY, CLEANING, AND GARMENT SERVICES"},
	{id: "7211", name: "LAUNDRY - FAMILY AND COMMERCIAL"},
	{id: "7216", name: "DRY CLEANERS"},
	{id: "7217", name: "CARPET AND UPHOLSTERY CLEANING"},
	{id: "7221", name: "PHOTOGRAPHIC STUDIOS"},
	{id: "7230", name: "BARBER AND BEAUTY SHOPS"},
	{id: "7251", name: "SHOP REPAIR SHOPS AND SHOE SHINE PARLORS, AND HAT CLEANING SHOPS"},
	{id: "7261", name: "FUNERAL SERVICE AND CREMATORIES"},
	{id: "7273", name: "DATING, ESCORT SERVICES"},
	{id: "7276", name: "TAX PREPARATION SERVICE"},
	{id: "7277", name: "COUNSELING SERVICE - DEBT, MARRIAGE, PERSONAL"},
	{id: "7278", name: "BUYING/SHOPPING SERVICES, CLUBS"},
	{id: "7296", name: "CLOTHING RENTAL - COSTUMES, FORMAL WEAR, UNIFORMS"},
	{id: "7297", name: "MASSAGE PARLORS"},
	{id: "7298", name: "HEALTH AND BEAUTY SHOPS"},
	{id: "7299", name: "MISCELLANEOUS PERSONAL SERVICES ( NOT ELSEWHERE CLASSIFIES)"},
	{id: "7311", name: "ADVERTISING SERVICES"},
	{id: "7321", name: "CONSUMER CREDIT REPORTING AGENCIES"},
	{id: "7332", name: "BLUEPRINTING AND PHOTOCOPYING SERVICES"},
	{id: "7333", name: "COMMERCIAL PHOTOGRAPHY, ART AND GRAPHICS"},
	{id: "7338", name: "QUICK COPY, REPRODUCTION AND BLUEPRINTING SERVICES"},
	{id: "7339", name: "STENOGRAPHIC AND SECRETARIAL SUPPORT SERVICES"},
	{id: "7342", name: "DISINFECTING SERVICES"},
	{id: "7349", name: "CLEANING AND MAINTENANCE, JANITORIAL SERVICES"},
	{id: "7361", name: "EMPLOYMENT AGENCIES, TEMPORARY HELP SERVICES"},
	{id: "7372", name: "COMPUTER PROGRAMMING, INTEGRATED SYSTEMS DESIGN AND DATA PROCESSING SERVICES"},
	{id: "7375", name: "INFORMATION RETRIEVAL SERVICES"},
	{id: "7379", name: "COMPUTER MAINTENANCE AND REPAIR SERVICES, NOT ELSEWHERE CLASSIFIED"},
	{id: "7392", name: "MANAGEMENT, CONSULTING, AND PUBLIC RELATIONS SERVICES"},
	{id: "7393", name: "PROTECTIVE AND SECURITY SERVICES - INCLUDING ARMORED CARS AND GUARD DOGS"},
	{
		id: "7394",
		name: "EQUIPMENT RENTAL AND LEASING SERVICES, TOOL RENTAL, FURNITURE RENTAL, AND APPLIANCE RENTAL"
	},
	{id: "7395", name: "PHOTOFINISHING LABORATORIES, PHOTO DEVELOPING"},
	{id: "7399", name: "BUSINESS SERVICES, NOT ELSEWHERE CLASSIFIED"},
	{id: "7512", name: "CAR RENTAL COMPANIES ( NOT LISTED BELOW)"},
	{id: "7513", name: "TRUCK AND UTILITY TRAILER RENTALS"},
	{id: "7519", name: "MOTOR HOME AND RECREATIONAL VEHICLE RENTALS"},
	{id: "7523", name: "AUTOMOBILE PARKING LOTS AND GARAGES"},
	{id: "7531", name: "AUTOMOTIVE BODY REPAIR SHOPS"},
	{id: "7534", name: "TIRE RE-TREADING AND REPAIR SHOPS"},
	{id: "7535", name: "PAINT SHOPS - AUTOMOTIVE"},
	{id: "7538", name: "AUTOMOTIVE SERVICE SHOPS"},
	{id: "7542", name: "CAR WASHES"},
	{id: "7549", name: "TOWING SERVICES"},
	{id: "7622", name: "RADIO REPAIR SHOPS"},
	{id: "7623", name: "AIR CONDITIONING AND REFRIGERATION REPAIR SHOPS"},
	{id: "7629", name: "ELECTRICAL AND SMALL APPLIANCE REPAIR SHOPS"},
	{id: "7631", name: "WATCH, CLOCK, AND JEWELRY REPAIR"},
	{id: "7641", name: "FURNITURE, FURNITURE REPAIR, AND FURNITURE REFINISHING"},
	{id: "7692", name: "WELDING REPAIR"},
	{id: "7699", name: "REPAIR SHOPS AND RELATED SERVICES - MISCELLANEOUS"},
	{id: "7800", name: "GAMBLING - GOVERNMENT-OWNED LOTTERIES"},
	{id: "7801", name: "GAMBLING - GOVERNMENT-LICENSED ON-LINE CASINOS"},
	{id: "7802", name: "GAMBLING - GOVERNMENT-LICENSED HORSE/DOG RACING"},
	{id: "7829", name: "MOTION PICTURES AND VIDEO TAPE PRODUCTION AND DISTRIBUTION"},
	{id: "7832", name: "MOTION PICTURE THEATERS"},
	{id: "7841", name: "DVD/VIDEO TAPE RENTAL STORES"},
	{id: "7911", name: "DANCE HALLS, STUDIOS AND SCHOOLS"},
	{id: "7922", name: "THEATRICAL PRODUCERS (EXCEPT MOTION PICTURES), TICKET AGENCIES"},
	{id: "7929", name: "BANDS, ORCHESTRAS, AND MISCELLANEOUS ENTERTAINERS (NOT ELSEWHERE CLASSIFIED)"},
	{id: "7932", name: "BILLIARD AND POOL ESTABLISHMENTS"},
	{id: "7933", name: "BOWLING ALLEYS"},
	{id: "7941", name: "COMMERCIAL SPORTS, ATHLETIC FIELDS, PROFESSIONAL SPORT CLUBS, AND SPORT PROMOTERS"},
	{id: "7991", name: "TOURIST ATTRACTIONS AND EXHIBITS"},
	{id: "7992", name: "GOLF COURSES - PUBLIC"},
	{id: "7993", name: "VIDEO AMUSEMENT GAME SUPPLIES"},
	{id: "7994", name: "ARCADES – VIDEO GAMES"},
	{id: "7995", name: "FANTASY SPORTS"},
	{id: "7996", name: "AMUSEMENT PARKS, CARNIVALS, CIRCUSES, FORTUNE TELLERS"},
	{
		id: "7997",
		name: "MEMBERSHIP CLUBS (SPORTS, RECREATION, ATHLETIC), COUNTRY CLUBS, AND PRIVATE GOLF COURSES"
	},
	{id: "7998", name: "AQUARIUMS, SEA-AQUARIUMS, DOLPHINARIUMS"},
	{id: "7999", name: "RECREATION SERVICES (NOT ELSEWHERE CLASSIFIED)"},
	{id: "8011", name: "DOCTORS AND PHYSICIANS (NOT ELSEWHERE CLASSIFIED)"},
	{id: "8021", name: "DENTISTS AND ORTHODONTISTS"},
	{id: "8031", name: "OSTEOPATHS"},
	{id: "8041", name: "CHIROPRACTORS"},
	{id: "8042", name: "OPTOMETRISTS AND OPHTHALMOLOGISTS"},
	{id: "8043", name: "OPTICIANS, OPTICIANS GOODS AND EYEGLASSES"},
	{id: "8044", name: "OPTICIANS, OPTICAL GOODS, AND EYEGLASSES (NO LONGER VALID FOR FIRST PRESENTMENTS)"},
	{id: "8049", name: "PODIATRISTS AND CHIROPODISTS"},
	{id: "8050", name: "NURSING AND PERSONAL CARE FACILITIES"},
	{id: "8062", name: "HOSPITALS"},
	{id: "8071", name: "MEDICAL AND DENTAL LABORATORIES"},
	{id: "8099", name: "MEDICAL SERVICES AND HEALTH PRACTITIONERS (NOT ELSEWHERE CLASSIFIED)"},
	{id: "8111", name: "LEGAL SERVICES AND ATTORNEYS"},
	{id: "8211", name: "ELEMENTARY AND SECONDARY SCHOOLS"},
	{id: "8220", name: "COLLEGES, JUNIOR COLLEGES, UNIVERSITIES, AND PROFESSIONAL SCHOOLS"},
	{id: "8241", name: "CORRESPONDENCE SCHOOLS"},
	{id: "8244", name: "BUSINESS AND SECRETARIAL SCHOOLS"},
	{id: "8249", name: "VOCATIONAL SCHOOLS AND TRADE SCHOOLS"},
	{id: "8299", name: "SCHOOLS AND EDUCATIONAL SERVICES ( NOT ELSEWHERE CLASSIFIED)"},
	{id: "8351", name: "CHILD CARE SERVICES"},
	{id: "8398", name: "CHARITABLE AND SOCIAL SERVICE ORGANIZATIONS"},
	{id: "8641", name: "CIVIC, FRATERNAL, AND SOCIAL ASSOCIATIONS"},
	{id: "8651", name: "POLITICAL ORGANIZATIONS"},
	{id: "8661", name: "RELIGIOUS ORGANIZATIONS"},
	{id: "8675", name: "AUTOMOBILE ASSOCIATIONS"},
	{id: "8699", name: "MEMBERSHIP ORGANIZATIONS ( NOT ELSEWHERE CLASSIFIED)"},
	{id: "8734", name: "TESTING LABORATORIES ( NON-MEDICAL)"},
	{id: "8911", name: "ARCHITECTURAL - ENGINEERING AND SURVEYING SERVICES"},
	{id: "8931", name: "ACCOUNTING, AUDITING, AND BOOKKEEPING SERVICES"},
	{id: "8999", name: "PROFESSIONAL SERVICES ( NOT ELSEWHERE DEFINED)"},
	{id: "9211", name: "COURT COSTS, INCLUDING ALIMONY AND CHILD SUPPORT"},
	{id: "9222", name: "FINES"},
	{id: "9223", name: "BAIL AND BOND PAYMENTS"},
	{id: "9311", name: "TAX PAYMENTS"},
	{id: "9399", name: "DEPARTMENT OF MOTOR VEHICLES"},
	{id: "9402", name: "POSTAGE STAMPS"},
	{id: "9405", name: "U.S. GOVERNMENT"},
	{id: "9700", name: "AUTOMATED REFERRAL SERVICE"},
	{id: "9702", name: "EMERGENCY SERVICE (GCAS) (FOR VISA USE ONLY)"},
	{id: "9950", name: "BUSINESS-TO-BUSINESS (INTRA-COMPANY)"},
]

export default mcc;
