import * as React from "react";
import {useRecordContext} from "react-admin";
import {Billing} from "../types";
import {getStatusColor} from "./BillingStatusField";
import {Link} from "@mui/material";

const BillingUrlField = () => {
    const record = useRecordContext<Billing>();
    const props = {textColor: getStatusColor("PDF"), addLabel: true, allowEmpty: true};
    const {...rest} = props;

    return (record?.billingState?.toLowerCase() !== "empty") ? (
        <Link
            href={record?.pdfDocUrl}
            variant="body2"
            sx={{color: getStatusColor(record?.billingState)}}
        >
            pdf
        </Link>
    ) : null;
};

BillingUrlField.defaultProps = {
    source: 'billingState',
    label: 'Invoice Doc',
};


export default BillingUrlField;
