import * as React from 'react';
import {memo} from 'react';
import {SxProps, Typography} from '@mui/material';

import {FieldProps, useRecordContext} from 'react-admin';
import {Business} from '../types';

interface Props extends FieldProps<Business> {
    size?: string;
    sx?: SxProps;
}

const FullNameField = (props: Props) => {
    const {size} = props;
    const record = useRecordContext<Business>();
    return record ? (
        <Typography
            variant="body2"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            component="div"
            sx={props.sx}
        >
            {record?.ownerDetails?.firstName} {record?.ownerDetails?.lastName}
        </Typography>
    ) : null;
};

FullNameField.defaultProps = {
    source: 'ownerDetails?.firstName',
    label: 'Business Owner Name',
};

export default memo<Props>(FullNameField);
