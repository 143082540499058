import * as React from "react";
import {memo} from "react";
import {FieldProps} from "react-admin";
import {Transaction} from "../types";
import {Typography} from "@mui/material";

interface Props extends FieldProps<Transaction> {
    size?: string;
}

const TransactionIdField = (props: Props) => {
    const {record, size} = props;
    const text = "Transaction ID:"
    return record ? (
        <div>
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
                sx={{margin: '5px 0'}}
            >
                {text} {record.transactionId}
            </Typography>
        </div>
    ) : null;
};

TransactionIdField.defaultProps = {
    source: 'transactionId',
    label: 'resources.r2p.fields.name',
};

export default memo<Props>(TransactionIdField);
