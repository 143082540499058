import * as React from 'react';
import {Admin, CustomRoutes, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Route} from 'react-router';

import authProvider from './auth/authProvider';
import {Layout, Login} from './layout';
import {Dashboard} from './dashboard';
import englishMessages from './i18n/en';
import {lightTheme} from './layout/themes';

import Configuration from './configuration/Configuration';
import tayDataProvider from './tayDataProvider';
import transactions from './transactions';
import relatedTransactions from './relatedTransactions';
import businesses from './businesses';
import payouts from './payout/payoutTransactions';
import billingList from './billingList';
import revenueList from './revenueList';
import directory from './directory';


const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    return (
        <Admin
            title=""
            dataProvider={tayDataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration/>}/>
            </CustomRoutes>
            <Resource name="businesses" {...businesses} />
            <Resource name="transactions" {...transactions} />
            <Resource name="relatedTransactions" {...relatedTransactions} />
            <Resource name="payouts" {...payouts} />
            <Resource name="billing" {...billingList} />
            <Resource name="revenue" {...revenueList} />
            <Resource name="directory" {...directory} />
        </Admin>
    );
};

export default App;
