import * as React from "react";
import {memo} from "react";
import {FieldProps} from "react-admin";
import {Billing} from "../types";
import {Typography} from "@mui/material";

interface Props extends FieldProps<Billing> {
    size?: string;
}

const BillingIdField = (props: Props) => {
    const {record, size} = props;
    return record ? (
        <Typography
            variant="body2"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            component="div"
            sx={{margin: '5px 0'}}
        >
            {record.businessId} {record.sk}
        </Typography>
    ) : null;
};

BillingIdField.defaultProps = {
    source: 'sk',
    label: 'resources.billing.fields.name',
};

export default memo<Props>(BillingIdField);
