import * as React from 'react';
import { Card, CardHeader, List } from '@mui/material';
import { useTranslate } from 'react-admin';

import {Transaction} from '../types';
import {PendingTransaction} from "./PendingTransaction";

interface Props {
    transactions?: Transaction[];
}

const PendingTransactions = (props: Props) => {
    const { transactions = [] } = props;
    const translate = useTranslate();

    return (
        <Card sx={{ flex: 1 }}>
            <CardHeader title={translate('pos.dashboard.pending_orders')} />
            <List dense={true}>
                {transactions.map(record => (
                    <PendingTransaction key={record.id} transaction={record} />
                ))}
            </List>
        </Card>
    );
};

export default PendingTransactions;
