import * as React from "react";
import {Fragment, useCallback} from "react";
import {
    AutocompleteInput,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
} from "react-admin";
import {Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";
import {Business} from "../types";
import BillingPeriodField from "./BillingPeriodField";
import BillingStatusField from "./BillingStatusField";
import BillingUrlField from "./BillingUrlField";

const BillingListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <ExportButton/>
    </TopToolbar>
);

const BillingList = () => (
    <List
        filterDefaultValues={{status: 'Invoiced'}}
        sort={{field: 'createdAt', order: 'DESC'}}
        perPage={50}
        filters={billingFilters}
        actions={<BillingListActions/>}
    >
        <TabbedDataGrid/>
    </List>
);

const matchSuggestion = (filterValue:any, choice?:any) => choice?.businessProfile?.businessLegalName?.toLowerCase().match(filterValue?.toLowerCase());

const billingFilters = [

    <SearchInput source="q" alwaysOn/>,
    <ReferenceInput source="businessId" reference="businesses" perPage={1000}>
        <AutocompleteInput
            matchSuggestion={matchSuggestion}
            optionText={(choice?: Business) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.businessProfile.businessLegalName}`
                    : ''
            }
        />
    </ReferenceInput>,
    <SelectInput
        source="billingState"
        choices={[
            {
                id: 'Draft',
                name: 'Draft',
            },
            {
                id: 'Empty',
                name: 'Empty',
            },
            {
                id: 'Invoiced',
                name: 'Invoiced',
            },
            {
                id: 'Requested',
                name: 'Requested',
            },
            {
                id: 'Cancelled',
                name: 'Cancelled',
            },
            {
                id: 'Paid',
                name: 'Paid',
                disabled: true,
            },
            {
                id: 'Failed',
                name: 'Failed',
                disabled: true,
            },

            {
                id: 'Voided',
                name: 'Voided',
                disabled: true,
            },
            {
                id: 'Returned',
                name: 'Returned',
                disabled: true,
            },
            {
                id: 'Error',
                name: 'Error',
                disabled: true,
            },
            {
                id: 'Submitted',
                name: 'Submitted',
            },
            {
                id: 'Processed',
                name: 'Processed',
            },
        ]

        }/>,
    <TextInput source="sk" label="Billing Period"/>,
    <NumberInput source="transactionFixedFee"/>,
    <NumberInput source="transactionVariableFee"/>,
    <DateInput source="createdAt" label="Date Since"/>,
    <DateInput source="createdAt" label="Date Until"/>,
];


const tabs = [
    {id: 'Invoiced', name: 'Invoiced'},
    {id: 'Paid', name: 'Paid'},
    {id: 'Processed', name: 'Processed'},
    {id: 'Draft', name: 'Draft'},
    {id: 'Cancelled', name: 'Cancelled'},
    {id: 'Empty', name: 'Empty'},
];

const TabbedDataGrid = () => {
    const billingListContext = useListContext();

    if (!Object.keys(billingListContext?.filterValues)?.length) { // fix a bug with remove filter
        billingListContext.filterValues["status"] = "Invoiced";
    }


    const {filterValues, setFilters, displayedFilters} = billingListContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                {...filterValues, status: value},
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.name,
                                    }}
                                    sx={{lineHeight: 'inherit'}}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            {isXSmall ? (
                <DatagridConfigurable
                    rowClick="edit"
                    bulkActionButtons={false}
                >
                    <BillingPeriodField/>
                    <BillingStatusField/>
                    <ReferenceField source="businessId" reference="businesses" label="Business ID">
                        <TextField source="businessId"/>
                    </ReferenceField>
                    <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                        <TextField source="businessProfile.businessLegalName"/>
                    </ReferenceField>
                    <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                    <DateField source="endDate" label="End Date (UTC) (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                    <NumberField
                        source="transactionFixedFee"
                        label="Fixed Fee"
                        options={{
                            style: 'currency',
                            currency: 'USD',
                        }}
                    />
                    <NumberField
                        source="transactionVariableFee"
                        label="Variable Fee"
                        options={{
                            style: 'currency',
                            currency: 'USD',
                        }}
                    />
                    <NumberField
                        source="monthlyFee"
                        label="Monthly Fee"
                        options={{
                            style: 'currency',
                            currency: 'USD',
                        }}
                    />
                    <NumberField
                        source="totalTransactionFees"
                        label="Total Fee"
                        options={{
                            style: 'currency',
                            currency: 'USD',
                        }}
                    />
                    <NumberField
                        source="totalBillingAmount"
                        label="Total Billing Amount"
                        options={{
                            style: 'currency',
                            currency: 'USD',
                        }}
                        sx={{fontWeight: 'bold'}}
                    />
                    <BillingUrlField/>
                </DatagridConfigurable>
            ) : (
                <>
                    {filterValues.status === 'Invoiced' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BillingPeriodField/>
                            <BillingStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <DateField source="endDate" label="End Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <NumberField
                                source="transactionFixedFee"
                                label="Fixed Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="transactionVariableFee"
                                label="Variable Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="monthlyFee"
                                label="Monthly Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalTransactionFees"
                                label="Total Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalBillingAmount"
                                label="Total Billing Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            <BillingUrlField/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Paid' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BillingPeriodField/>
                            <BillingStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <DateField source="endDate" label="End Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <NumberField
                                source="transactionFixedFee"
                                label="Fixed Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="transactionVariableFee"
                                label="Variable Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="monthlyFee"
                                label="Monthly Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalTransactionFees"
                                label="Total Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalBillingAmount"
                                label="Total Billing Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            <BillingUrlField/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Processed' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BillingPeriodField/>
                            <BillingStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <DateField source="endDate" label="End Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <NumberField
                                source="transactionFixedFee"
                                label="Fixed Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="transactionVariableFee"
                                label="Variable Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="monthlyFee"
                                label="Monthly Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalTransactionFees"
                                label="Total Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalBillingAmount"
                                label="Total Billing Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            <BillingUrlField/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Draft' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BillingPeriodField/>
                            <BillingStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <DateField source="endDate" label="End Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <NumberField
                                source="transactionFixedFee"
                                label="Fixed Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="transactionVariableFee"
                                label="Variable Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="monthlyFee"
                                label="Monthly Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalTransactionFees"
                                label="Total Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalBillingAmount"
                                label="Total Billing Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            <BillingUrlField/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Cancelled' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BillingPeriodField/>
                            <BillingStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <DateField source="endDate" label="End Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <NumberField
                                source="transactionFixedFee"
                                label="Fixed Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="transactionVariableFee"
                                label="Variable Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="monthlyFee"
                                label="Monthly Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalTransactionFees"
                                label="Total Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalBillingAmount"
                                label="Total Billing Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            <BillingUrlField/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 'Empty' && (
                        <DatagridConfigurable
                            rowClick="edit"
                            bulkActionButtons={false}
                        >
                            <BillingPeriodField/>
                            <BillingStatusField/>
                            <ReferenceField source="businessId" reference="businesses" label="Business ID">
                                <TextField source="businessId"/>
                            </ReferenceField>
                            <ReferenceField source="businessId" reference="businesses" label="Business Legal Name">
                                <TextField source="businessProfile.businessLegalName"/>
                            </ReferenceField>
                            <DateField source="startDate" label="Start Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <DateField source="endDate" label="End Date (UTC)" showTime options={{ timeZone: 'UTC' }}/>
                            <NumberField
                                source="transactionFixedFee"
                                label="Fixed Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="transactionVariableFee"
                                label="Variable Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="monthlyFee"
                                label="Monthly Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalTransactionFees"
                                label="Total Fee"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                            />
                            <NumberField
                                source="totalBillingAmount"
                                label="Total Billing Amount"
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            <BillingUrlField/>
                        </DatagridConfigurable>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default BillingList;
