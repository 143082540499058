import * as React from "react";
import {useRecordContext} from "react-admin";
import {Revenue} from "../types";
import {blue, deepOrange, deepPurple} from "@mui/material/colors";
import {Typography} from "@mui/material";

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const RevenuePeriodField = () => {
    const record = useRecordContext<Revenue>();
    const period = record.sk.slice(7);
    let d = new Date(record.startDate);
    const displayString = monthNames[d.getUTCMonth()] + ", " + d.getFullYear();
    return record ? (
        <Typography flex="1" sx={{color: getPeriodColor(record?.revenueState), fontSize: 14}}>
            {displayString}
        </Typography>
    ) : null;
};

RevenuePeriodField.defaultProps = {
    source: 'sk',
    label: 'Period',
};

function getPeriodColor(state: string | undefined) {
    if (state) {
        return blue["500"];
    }

    // fallback
    return "#000000";
}

export default RevenuePeriodField;
