import TransactionIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import PayoutTransactionList from './PayoutTransactionList';
import PayoutTransactionEdit from './PayoutTransactionEdit';

export default {
    list: PayoutTransactionList,
    edit: PayoutTransactionEdit,
    TransactionIcon: KeyboardArrowRightIcon,
};
