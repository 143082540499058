const corpStruct = [
	{id : "0_1", name : "Sole Proprietorship"},
	{id : "0_4", name : "Government Entity"},
	{id : "0_5", name : "Public Corporation"},
	{id : "0_6", name : "Company Registered with SEC"},
	{id : "0_7", name : "Financial Institution"},
	{id : "1_1", name : "Non-Profit"},
	{id : "1_2", name : "Non-Excluded Pooled Investment Vehicle"},
	{id : "2_1", name : "Limited Liability Company (LLC, Ltd, LC, PLLC)"},
	{id : "2_2", name : "Partnership (LP, LLP, LLLP, GP)"},
	{id : "2_3", name : "C Corporation"},
	{id : "2_4", name : "S Corporation"},
	{id : "2_5", name : "Trust (Business Trust)"},
	{id : "2_6", name : "Joint Venture"},
]
export default corpStruct;


