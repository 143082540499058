import * as React from 'react';
import {
    BooleanInput,
    DateTimeInput,
    Edit,
    email,
    FieldProps,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput, useRecordContext,
    useTranslate,
} from 'react-admin';
import {Box, Grid, Step, StepLabel, Stepper, Typography} from '@mui/material';

import {Transaction} from "../types";
import TransactionEditToolbar from './TransactionEditToolbar';
import TransactionIdField from "./TransactionIdField";
import ACHSubmitField from "./ACHSubmitField";

const Separator = () => <Box pt="1em"/>;

const StateTimelines = () => {

    const record = useRecordContext<Transaction>();
    // @ts-ignore
    const {stateTimeline = []} = record;
    const translate = useTranslate();
    const [activeStep, setActiveStep] = React.useState(stateTimeline?.length);
    return (
        <Box sx={{maxWidth: 250}}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {stateTimeline.map((step: any) => (
                    <Step key={step.state}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">{new Date(step.date).toLocaleString()}</Typography>
                            }
                        >
                            {step.state}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};


const TransactionEdit = () => {
    const translate = useTranslate();
    return (
        <Edit title={<TransactionTitle/>}>
            <SimpleForm
               /* validate={validateForm}*/
                toolbar={<TransactionEditToolbar/>}
            >
                <div>
                    <Grid container width={{xs: '100%', xl: 1400}} spacing={2}>
                        <Grid item xs={12} md={8}>


                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.paymentRequest'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        type={"string"}
                                        source="paymentRequest.paymentDescription"
                                        label='resources.r2p.page.paymentRequest.paymentDescription'
                                        resource="transactions"
                                        fullWidth
                                        multiline={true}
                                        resettable={true}
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <DateTimeInput
                                        source="paymentRequest.serviceDate"
                                        label='resources.r2p.page.paymentRequest.serviceDate'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <NumberInput
                                        source="paymentRequest.serviceAmount"
                                        label='resources.r2p.page.paymentRequest.serviceAmount'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <NumberInput
                                        source="paymentRequest.taxPercentage"
                                        label='resources.r2p.page.paymentRequest.taxPercentage'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <NumberInput
                                        source="paymentRequest.totalAmount"
                                        label='resources.r2p.page.paymentRequest.totalAmount'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <NumberInput
                                        step={1}
                                        source="paymentRequest.referenceInvoiceNumber"
                                        label='resources.r2p.page.paymentRequest.referenceInvoiceNumber'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="paymentRequest.invoiceId"
                                        label='resources.r2p.page.paymentRequest.invoiceId'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <BooleanInput
                                        row={true}
                                        resource="transactions"
                                        source="paymentRequest.invoiceDocAvailable"
                                        label='resources.r2p.page.paymentRequest.invoiceDocAvailable'
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="paymentRequest.invoiceDocUrl"
                                        label='resources.r2p.page.paymentRequest.invoiceDocUrl'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>

                            <Separator/>
                            <Box mt="1em"/>

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.businessInformation'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="businessInformation.businessLegalName"
                                        label='resources.r2p.page.businessInformation.businessLegalName'
                                        resource="transactions"
                                        fullWidth
                                        disabled={true}
                                        helperText={false}
                                    />
                                </Box>
                            </Box>

                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="businessInformation.firstName"
                                        label='resources.r2p.page.businessInformation.firstName'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessInformation.lastName"
                                        label='resources.r2p.page.businessInformation.lastName'
                                        resource="transactions"
                                        fullWidth
                                        disabled={true}
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="businessInformation.clientId"
                                        label='resources.r2p.page.businessInformation.clientId'
                                        resource="transactions"
                                        fullWidth
                                        disabled={true}
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        type="email"
                                        source="businessInformation.businessEmail"
                                        label='resources.r2p.page.businessInformation.businessEmail'
                                        resource="transactions"
                                        fullWidth
                                        disabled={true}
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.page.businessInformation.logo.details'
                                )}
                            </Typography>

                            <Box flex={1} >
                                <Box display={{xs: 'block', sm: 'flex'}}>
                                    <Box flex={1} >
                                        <BooleanInput
                                            row={true}
                                            resource="transactions"
                                            source="businessInformation.logo.logoAvailable"
                                            label='resources.r2p.page.businessInformation.logo.logoAvailable'
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                        <TextInput
                                            source="businessInformation.logo.displayLogoUrl"
                                            label='resources.r2p.page.businessInformation.logo.displayLogoUrl'
                                            resource="transactions"
                                            fullWidth
                                            disabled={true}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Separator/>
                            <Box mt="1em"/>

                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.r2p.page.businessInformation.accountInformation.details'
                                        )}
                                    </Typography>








                                    <TextInput
                                        source="businessInformation.accountInformation.businessDirectoryId"
                                        resource="transactions"
                                        label='resources.r2p.page.businessInformation.accountInformation.businessDirectoryId'
                                        multiline
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.accountInformation.businessBankName"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.businessInformation.accountInformation.businessBankName'
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.accountInformation.businessDisplayAccountNumber"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.businessInformation.accountInformation.businessDisplayAccountNumber'
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.r2p.page.businessInformation.businessAddress.address'
                                        )}
                                    </Typography>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.businessAddress.addressLineOne"
                                                resource="transactions"
                                                label='resources.r2p.page.businessInformation.businessAddress.addressLineOne'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.businessAddress.addressLineTwo"
                                                resource="transactions"
                                                label='resources.r2p.page.businessInformation.businessAddress.addressLineTwo'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.businessAddress.city"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.businessInformation.businessAddress.city'
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.businessAddress.addressState"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.businessInformation.businessAddress.addressState'
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                        <Box flex={1} >
                                            <TextInput
                                                source="businessInformation.businessAddress.zipcode"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.businessInformation.businessAddress.zipcode'
                                                helperText={false}
                                                disabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Separator/>
                            <Box mt="1em"/>

                            {/* Consumer Information */}
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.consumerInformation'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    {/*consumer information fields here*/}
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.r2p.page.consumerInformation.details'
                                        )}
                                    </Typography>
                                    <Box flex={1}>
                                        <TextInput
                                            source="consumerInformation.firstName"
                                            resource="transactions"
                                            fullWidth
                                            label='resources.r2p.page.consumerInformation.firstName'
                                            helperText={false}
                                        />
                                    </Box>
                                    <Box flex={1}>
                                        <TextInput
                                            source="consumerInformation.lastName"
                                            resource="transactions"
                                            fullWidth
                                            label='resources.r2p.page.consumerInformation.lastName'
                                            helperText={false}
                                        />
                                    </Box>
                                    <Box flex={1} >
                                        <TextInput
                                            source="consumerInformation.consumerMobileNumber"
                                            resource="transactions"
                                            fullWidth
                                            label='resources.r2p.page.consumerInformation.consumerMobileNumber'
                                            helperText={false}
                                        />
                                    </Box>
                                    <Box flex={1} >
                                        <TextInput
                                            type="email"
                                            source="consumerInformation.email"
                                            resource="transactions"
                                            fullWidth
                                            label='resources.r2p.page.consumerInformation.email'
                                            helperText={false}
                                        />
                                    </Box>
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    {/*consumer information account details here*/}
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.r2p.page.consumerInformation.accountInformation.details'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="consumerInformation.accountInformation.consumerDirectoryId"
                                        resource="transactions"
                                        label='resources.r2p.page.consumerInformation.accountInformation.consumerDirectoryId'
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="consumerInformation.accountInformation.consumerDisplayAccountNumber"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.consumerInformation.accountInformation.consumerDisplayAccountNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} >
                                            <TextInput
                                                source="consumerInformation.accountInformation.consumerBankName"
                                                resource="transactions"
                                                fullWidth
                                                label='resources.r2p.page.consumerInformation.accountInformation.consumerBankName'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Separator/>
                            <Box mt="1em"/>

                            {/* Transaction Other Information */}
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.transactionOtherInfo'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <TextInput
                                    source="transactionOtherInfo.resendCount"
                                    resource="transactions"
                                    label='resources.r2p.page.transactionOtherInfo.resendCount'
                                    multiline
                                    fullWidth
                                    helperText={false}
                                    disabled={true}
                                />
                            </Box>

                            <Separator/>
                            <Box mt="1em"/>

                            {/* L2P Response Data */}
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.l2pResponseData'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.contactProfileId"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.contactProfileId'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.transPymtId"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.transPymtId'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.accountMask"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.accountMask'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.transId"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.transId'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.paymentProfileId"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.paymentProfileId'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.accountType"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.accountType'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="l2pResponseData.transactionRsp.status.responseCode"
                                        label='resources.r2p.page.l2pResponseData.transactionRsp.status.responseCode'
                                        resource="transactions"
                                        fullWidth
                                        helperText={false}
                                        disabled={true}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>
                            <ACHSubmitField/>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.identity'
                                )}
                            </Typography>
                            <div>
                                <SelectInput
                                    helperText={false}
                                    fullWidth
                                    resource="transactions"
                                    source="transactionState"
                                    label='resources.r2p.page.transactionState'
                                    choices={[
                                        {
                                            id: 'Draft',
                                            name: 'Draft',
                                        },
                                        {
                                            id: 'Pending',
                                            name: 'Pending',
                                        },
                                        {
                                            id: 'Approved',
                                            name: 'Approved',
                                        },
                                        {
                                            id: 'Sent',
                                            name: 'Sent',
                                        },
                                        {
                                            id: 'Resent',
                                            name: 'Resent',
                                        },
                                        {
                                            id: 'Rejected',
                                            name: 'Rejected',
                                        },
                                        {
                                            id: 'Expired',
                                            name: 'Expired',
                                        },
                                        {
                                            id: 'Cancelled',
                                            name: 'Cancelled',
                                        },
                                        {
                                            id: 'Withdrawn',
                                            name: 'Withdrawn',
                                        },
                                        {
                                            id: 'Submitted',
                                            name: 'Submitted',
                                        },
                                        {
                                            id: 'Copied',
                                            name: 'Copied',
                                        },
                                        {
                                            id: 'Paid',
                                            name: 'Paid',
                                            disabled: true,
                                        },
                                        {
                                            id: 'Failed',
                                            name: 'Failed',
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </div>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        type={"string"}
                                        source="transactionId"
                                        resource="transactions"
                                        label='resources.r2p.page.transactionId'
                                        disabled={true}
                                        helperText={false}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        type="businessId"
                                        source="businessId"
                                        resource="transactions"
                                        label='resources.r2p.page.businessId'
                                        validate={[required()]}
                                        disabled={true}
                                        helperText={false}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <TextInput
                                        source="consumerMobileNumber"
                                        resource="transactions"
                                        label='resources.r2p.page.consumerMobileNumber'
                                        helperText={false}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} >
                                    <NumberInput
                                        source="invoiceNumber"
                                        step={1}
                                        resource="transactions"
                                        label='resources.r2p.page.invoiceNumber'
                                        helperText={false}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <div>
                                <DateTimeInput
                                    source="createdAt"
                                    resource="transactions"
                                    label='resources.r2p.page.createdAt'
                                    helperText={false}
                                    disabled={true}
                                    fullWidth
                                />
                            </div>
                            <div>
                                <DateTimeInput
                                    source="notificationSentDate"
                                    resource="transactions"
                                    label='resources.r2p.page.notificationSentDate'
                                    helperText={false}
                                    disabled={true}
                                    fullWidth
                                />
                            </div>
                            <div>
                                <TextInput
                                    source="timeToLive"
                                    resource="transactions"
                                    label='resources.r2p.page.timeToLive'
                                    helperText={false}
                                    fullWidth
                                />
                            </div>
                            <Separator/>
                            <Box mt="1em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.r2p.fieldGroups.stateTimeline'
                                )}
                            </Typography>
                            <StateTimelines/>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

const TransactionTitle = () => {
    const record = useRecordContext<Transaction>();
    return record ? <TransactionIdField record={record} size="32"/> : null;
}

export default TransactionEdit;
