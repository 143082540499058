import * as React from 'react';
import {
    Avatar,
    Box,
    Button, Card, CardHeader,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import { useTranslate, useGetList } from 'react-admin';
import { subDays } from 'date-fns';

import CardWithIcon from './CardWithIcon';
import { Business } from '../types';
import {PendingTransaction} from "./PendingTransaction";

const NewBusinesses = () => {
    const translate = useTranslate();

    const aMonthAgo = subDays(new Date(), 30);
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    aMonthAgo.setHours(0);
    aMonthAgo.setMinutes(0);
    aMonthAgo.setSeconds(0);
    aMonthAgo.setMilliseconds(0);

    const { isLoading, data: businesses } = useGetList<Business>('businesses', {
        filter: {
            date_gte: aMonthAgo.toISOString(),
        },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: { page: 1, perPage: 100 },
    });


    const nb = businesses ? businesses.reduce((nb: number) => ++nb, 0) : 0;
    return (
        <CardWithIcon
            to="/businesses"
            icon={BusinessIcon}
            title={translate('pos.dashboard.new_customers')}
            subtitle={nb}
        >
            <List sx={{ display: isLoading ? 'none' : 'block' }}>
                {businesses
                    ? businesses.map((record: Business) => (
                          <ListItem
                              button
                              to={`/businesses/${record?.businessId}`}
                              component={Link}
                              key={record?.id}
                          >
                              <ListItemAvatar>
                                  <Avatar src={`${record?.businessProfile?.logoUrl}?size=64x64`} />
                              </ListItemAvatar>
                              <ListItemText
                                  primary={`${record?.businessProfile?.businessLegalName}`}
                              />
                          </ListItem>
                      ))
                    : null}
            </List>
            <Box flexGrow={1}>&nbsp;</Box>
            <Button
                sx={{ borderRadius: 0 }}
                component={Link}
                to="/businesses"
                size="small"
                color="primary"
            >
                <Box p={1} sx={{ color: 'primary.main' }}>
                    {translate('pos.dashboard.all_customers')}
                </Box>
            </Button>
        </CardWithIcon>
    );
};

export default NewBusinesses;
