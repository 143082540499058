import * as React from 'react';
import { Fragment } from 'react';
import Toolbar from '@mui/material/Toolbar';

import {
    SaveButton,
    ToolbarProps,
    useRecordContext,
    useNotify,
    useRedirect,
} from 'react-admin';
import { Review } from '../types';

const BusinessEditToolbar = (props: ToolbarProps) => {
    const { resource } = props;
    const redirect = useRedirect();
    const notify = useNotify();

    const record = useRecordContext<Review>(props);

    if (!record) return null;
    return (
        <Toolbar
            sx={{
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'space-between',
                minHeight: { sm: 0 },
                marginBottom: '1em',
            }}
        >
            {record.businessState === 'Pending' ? (
                <Fragment>
                    <SaveButton
                        label="resources.businesses.fields.save"
                        mutationOptions={{
                            onSuccess: () => {
                                notify('ra.notification.updated', {
                                    type: 'info',
                                    messageArgs: { smart_count: 1 },
                                    undoable: true,
                                });
                                redirect('list', 'businesses');
                            },
                        }}
                        type="button"
                    />
                    <SaveButton
                        label="resources.businesses.fields.submit"
                        mutationOptions={{
                            onSuccess: () => {
                                notify('ra.notification.updated', {
                                    type: 'info',
                                    messageArgs: { smart_count: 1 },
                                    undoable: true,
                                });
                                redirect('list', 'businesses');
                            },
                        }}
                        transform={data => ({ ...data, onboardL2P: true })}
                        type="button"
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <SaveButton
                        mutationOptions={{
                            onSuccess: () => {
                                notify('ra.notification.updated', {
                                    type: 'info',
                                    messageArgs: { smart_count: 1 },
                                    undoable: true,
                                });
                                redirect('list', 'businesses');
                            },
                        }}
                        type="button"
                    />
                </Fragment>
            )}
        </Toolbar>
    );
};

export default BusinessEditToolbar;
