import * as React from "react";
import {BooleanInput, DateInput, TextInput, useRecordContext, useTranslate} from "react-admin";
import {Business} from "../types";
import {Box, Typography} from "@mui/material";
import {ShowErrorBlock} from "./ShowErrorBlock";

const Separator = () => <Box pt="1em"/>;

const MerchantKeysInfo = () => {
    const record = useRecordContext<Business>();
    const translate = useTranslate();
    if (!record) return null;

    if (!(record?.merchantKeys?.cardknox)) return null;

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                {translate(
                    'resources.businesses.page.merchantKey.label'
                )}
            </Typography>
            <Box display={{xs: 'block', sm: 'flex'}}>
                <Typography variant="h6" gutterBottom>
                    {translate(
                        'resources.businesses.page.merchantKey.cardKnox.label'
                    )}
                </Typography>
            </Box>
            <Box display={{xs: 'block', sm: 'flex'}}>
                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                    <TextInput
                        source="merchantKeys.cardknox.xKey"
                        resource="businesses"
                        label='resources.businesses.page.merchantKey.cardKnox.xKey'
                        multiline
                        fullWidth
                        helperText={false}
                        disabled={true}
                    />
                    <Box display={{xs: 'block', sm: 'flex'}} >
                        <Box flex={2}>
                            <TextInput
                                source="merchantKeys.cardknox.agreementToken"
                                resource="businesses"
                                fullWidth
                                label='resources.businesses.page.merchantKey.cardKnox.agreementToken'
                                helperText={false}
                                disabled={true}
                                sx={{ background: "#ffffff", }}
                            />
                        </Box>
                    </Box>
                    <Box display={{xs: 'block', sm: 'flex'}}>
                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                            <TextInput
                                source="merchantKeys.cardknox.refnum"
                                resource="businesses"
                                fullWidth
                                label='resources.businesses.page.merchantKey.cardKnox.refNum'
                                helperText={false}
                                disabled={true}
                            />
                        </Box>
                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                            <TextInput
                                source="merchantKeys.cardknox.appId"
                                resource="businesses"
                                fullWidth
                                label='resources.businesses.page.merchantKey.cardKnox.appId'
                                helperText={false}
                                disabled={true}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                    <TextInput
                        source="merchantKeys.cardknox.pendingPaymentSiteUrl"
                        resource="businesses"
                        label='resources.businesses.page.merchantKey.cardKnox.paymentSiteUrl'
                        multiline
                        fullWidth
                        helperText={false}
                        disabled={true}
                    />
                    <Box display={{xs: 'block', sm: 'flex'}}>
                        <Box flex={2}>
                            <DateInput
                                source="merchantKeys.cardknox.dateUpdated"
                                resource="businesses"
                                fullWidth
                                label='resources.businesses.page.merchantKey.cardKnox.dateUpdated'
                                helperText={false}
                                disabled={true}
                            />
                        </Box>
                    </Box>
                    <Box display={{xs: 'block', sm: 'flex'}}>
                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                            <BooleanInput
                                row={true}
                                resource="businesses"
                                source="merchantKeys.cardknox.onboardingSubmitted"
                                label='resources.businesses.page.merchantKey.cardKnox.onboardingSubmitted'
                                helperText={false}
                                disabled={true}
                            />
                        </Box>
                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                            <TextInput
                                source="merchantKeys.cardknox.processor"
                                resource="businesses"
                                fullWidth
                                label='resources.businesses.page.merchantKey.cardKnox.processor'
                                helperText={false}
                                disabled={true}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ShowErrorBlock/>

        </div>
    );
}

export default MerchantKeysInfo;
