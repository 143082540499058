import * as React from 'react';
import DollarIcon from '@mui/icons-material/AttachMoney';
import {useTranslate} from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const AllTransactionAmount = (props: Props) => {
    const {value} = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/transactions"
            icon={DollarIcon}
            title={translate('pos.dashboard.all_revenue')}
            subtitle={value}
        />
    );
};

export default AllTransactionAmount;
