import * as React from "react";
import {BooleanInput, useRecordContext, useTranslate} from "react-admin";
import {Business} from "../types";
import {Box, Typography} from "@mui/material";

const Separator = () => <Box pt="1em"/>;

const ReceiptFeatureInfo = () => {
    const record = useRecordContext<Business>();
    const translate = useTranslate();
    if (!record) return null;
    return (
        <div>
            <Separator/>
            <Typography variant="subtitle1" gutterBottom sx={{
                color: "#40BFD9",
                fontStyle: "italic"
            }}>
                {translate(
                    'resources.businesses.features.receiptLabel'
                )}
            </Typography>
            <Box display={{xs: 'block', sm: 'flex'}}>
                <Box
                    flex={1}
                    mr={{xs: 0, sm: '0.5em'}}
                >
                    <BooleanInput
                        row={true}
                        resource="businesses"
                        source="addTINToDigitalReceipt"
                        label='resources.businesses.features.receipt'
                        fullWidth
                    />
                </Box>
            </Box>
        </div>
    );
};
export default ReceiptFeatureInfo;
