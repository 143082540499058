const devEnvironment = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_3EmSkhe6g',
    userPoolWebClientId: '4unlm6bdvakhdjju5lfj18ovsp',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    //storage: sessionStorage,
    oauth: {
        domain: 'https://admin-user-pool-domain-dev-139112388416.auth.us-east-1.amazoncognito.com',
        scope: ['given name', 'email', 'locale', 'family name', 'openid'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
};
const demoEnvironment = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_fYbDCMrht',
    userPoolWebClientId: '2cr5j5o2phbol13t78vuqbh4eu',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    //storage: sessionStorage,
    oauth: {
        domain: 'https://admin-user-pool-domain-demo-645630069933.auth.us-east-1.amazoncognito.com',
        scope: ['given name', 'email', 'locale', 'family name', 'openid'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
};
const productionEnvironment = {
    region: 'us-west-1',
    userPoolId: 'us-west-1_z8R3f2mat',
    userPoolWebClientId: '1dcg2k8bup9i2jmiqo60nsirc2',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    //storage: sessionStorage,
    oauth: {
        domain: 'https://auto.taynow.com',
        scope: ['given name', 'email', 'locale', 'family name', 'openid'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
};
const config = {
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_3EmSkhe6g',
        userPoolWebClientId: '4unlm6bdvakhdjju5lfj18ovsp',
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        //storage: sessionStorage,
        oauth: {
            domain: 'https://admin-user-pool-domain-dev-139112388416.auth.us-east-1.amazoncognito.com',
            scope: ['given name', 'email', 'locale', 'family name', 'openid'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },

};

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
const isDemo = process.env.REACT_APP_NODE_ENV === 'demo';
const isDev = process.env.REACT_APP_NODE_ENV === 'dev';

console.log(process.env);

if (isDemo) {
    console.log('demo environment');
    config.Auth = { ... config.Auth, ...demoEnvironment };
} else if (isProduction) {
    console.log('production environment');
    config.Auth = { ... config.Auth, ...productionEnvironment };
} else if (isDev) {
    console.log('development environment');
    config.Auth = { ... config.Auth, ...devEnvironment };
} else {
    console.log('default environment');
}

export default config;
