import * as React from 'react';
import {
    ArrayField,
    BooleanInput,
    Datagrid,
    DateInput,
    DateTimeInput,
    Edit,
    email,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin';
import {Box, Divider, Grid, Typography} from '@mui/material';

import BusinessLegalNameField from "./BusinessLegalNameField";
import corpStruct from "./corpStruct";
import mcc from "./mcc";
import BusinessEditToolbar from "./BusinessEditToolbar";
import CardKnoxFeatureInfo from "./CardKnoxFeatureInfo";
import MerchantKeysInfo from "./MerchantKeysInfo";
import ReceiptFeatureInfo from "./ReceiptFeatureInfo";

const Separator = () => <Box pt="1em"/>;

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.first_name) {
        errors.first_name = 'ra.validation.required';
    }
    if (!values.last_name) {
        errors.last_name = 'ra.validation.required';
    }
    if (!values.email) {
        errors.email = 'ra.validation.required';
    } else {
        const error = email()(values.email);
        if (error) {
            errors.email = error;
        }
    }
    if (values.password && values.password !== values.confirm_password) {
        errors.confirm_password =
            'resources.customers.errors.password_mismatch';
    }
    return errors;
};

const BusinessEdit = () => {
    const translate = useTranslate();
    return (
        <Edit title={<BusinessTitle/>}>
            <SimpleForm
                toolbar={<BusinessEditToolbar/>}
            >
                <div>
                    <Grid container width={{xs: '100%', xl: 1400}} spacing={2}>
                        <Grid item xs={12} md={8}>

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.fieldGroups.businessProfile'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessProfile.businessLegalName"
                                        label='resources.businesses.page.businessProfile.businessLegalName'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessProfile.businessLicense"
                                        label='resources.businesses.page.businessProfile.businessLicense'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessProfile.doingBusinessAsName"
                                        label='resources.businesses.page.businessProfile.doingBusinessAsName'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessProfile.businessType"
                                        label='resources.businesses.page.businessProfile.businessType'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessProfile.taxPayerId"
                                        label='resources.businesses.page.businessProfile.taxPayerId'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        source="businessProfile.yearsInBusiness"
                                        label='resources.businesses.page.businessProfile.yearsInBusiness'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <div>
                                        <SelectInput
                                            fullWidth
                                            resource="businesses"
                                            source="businessProfile.corpStructure"
                                            label='resources.businesses.page.businessProfile.corpStructure'
                                            choices={corpStruct}
                                            helperText={false}
                                        />
                                    </div>
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <div>
                                        <SelectInput
                                            fullWidth
                                            resource="businesses"
                                            source="businessProfile.mccCode"
                                            label='resources.businesses.page.businessProfile.mccCode'
                                            choices={mcc}
                                            helperText={false}
                                        />
                                    </div>
                                </Box>
                            </Box>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <BooleanInput
                                        row={true}
                                        resource="businesses"
                                        source="businessProfile.logoAvailable"
                                        label='resources.businesses.page.businessProfile.logoAvailable'
                                        helperText={false}
                                    />
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <TextInput
                                        resource="businesses"
                                        source="businessProfile.logoUrl"
                                        label='resources.businesses.page.businessProfile.logoUrl'
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.addresses'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.businessAddress.address'
                                        )}
                                    </Typography>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="businessAddress.addressLineOne"
                                                resource="businesses"
                                                label='resources.businesses.page.businessAddress.addressLineOne'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="businessAddress.addressLineTwo"
                                                resource="businesses"
                                                label='resources.businesses.page.businessAddress.addressLineTwo'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="businessAddress.city"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.businessAddress.city'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="businessAddress.addressState"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.businessAddress.addressState'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={2}>
                                            <TextInput
                                                source="businessAddress.zipcode"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.businessAddress.zipcode'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.mailingAddress.address'
                                        )}
                                    </Typography>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="mailingAddress.addressLineOne"
                                                resource="businesses"
                                                label='resources.businesses.page.mailingAddress.addressLineOne'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="mailingAddress.addressLineTwo"
                                                resource="businesses"
                                                label='resources.businesses.page.mailingAddress.addressLineTwo'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="mailingAddress.city"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.mailingAddress.city'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="mailingAddress.addressState"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.mailingAddress.addressState'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={2}>
                                            <TextInput
                                                source="mailingAddress.zipcode"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.mailingAddress.zipcode'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Separator/>
                            <Box mt="2em"/>

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.paymentProfile.profile'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.paymentProfile.accountDetails.details'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="paymentProfile.accountDetails.directoryId"
                                        resource="businesses"
                                        label='resources.businesses.page.paymentProfile.accountDetails.directoryId'
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="paymentProfile.accountDetails.bankName"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.paymentProfile.accountDetails.bankName'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="paymentProfile.accountDetails.displayRoutingNumber"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.paymentProfile.accountDetails.displayRoutingNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={2}>
                                            <TextInput
                                                source="paymentProfile.accountDetails.displayAccountNumber"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.paymentProfile.accountDetails.displayAccountNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.paymentProfile.transactionDetails.details'
                                        )}
                                    </Typography>
                                    <NumberInput
                                        source="paymentProfile.transactionDetails.averageTransactionValue"
                                        label='resources.businesses.page.paymentProfile.transactionDetails.averageTransactionValue'
                                        resource="businesses"
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <NumberInput
                                                source="paymentProfile.transactionDetails.maximumTransactionValue"
                                                label='resources.businesses.page.paymentProfile.transactionDetails.maximumTransactionValue'
                                                resource="businesses"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <NumberInput
                                                step={1}
                                                source="paymentProfile.transactionDetails.monthlyAverageReturnsNumber"
                                                label='resources.businesses.page.paymentProfile.transactionDetails.monthlyAverageReturnsNumber'
                                                resource="businesses"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1}>
                                            <NumberInput
                                                step={1}
                                                source="paymentProfile.transactionDetails.monthlyProjectedVolume"
                                                label='resources.businesses.page.paymentProfile.transactionDetails.monthlyProjectedVolume'
                                                resource="businesses"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1}>
                                            <NumberInput
                                                step={1}
                                                source="paymentProfile.transactionDetails.monthlyTransactionsNumber"
                                                label='resources.businesses.page.paymentProfile.transactionDetails.monthlyTransactionsNumber'
                                                resource="businesses"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="2em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.ownerDetails.details'
                                )}
                            </Typography>

                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box
                                            flex={1}
                                            mr={{xs: 0, sm: '0.5em'}}
                                        >
                                            <TextInput
                                                source="ownerDetails.title"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.title'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={4} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="ownerDetails.firstName"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.firstName'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <DateInput
                                                source="ownerDetails.dateOfBirth"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.dateOfBirth'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="ownerDetails.displaySsn"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.displaySsn'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="ownerDetails.lastName"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.lastName'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="ownerDetails.email"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.email'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="2em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.ownerDetails.driverLicense.details'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="ownerDetails.driverLicence.displayLicenseNumber"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.driverLicense.displayLicenseNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <DateInput
                                                source="ownerDetails.driverLicence.expiryDate"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.driverLicense.expiryDate'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <DateInput
                                                source="ownerDetails.driverLicence.issuingDate"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.driverLicense.issuingDate'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="ownerDetails.driverLicence.issuingState"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.ownerDetails.driverLicense.issuingState'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <NumberInput
                                                source="ownerDetails.ownershipPercent"
                                                label='resources.businesses.page.ownerDetails.ownershipPercent'
                                                resource="businesses"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <BooleanInput
                                                row={true}
                                                resource="businesses"
                                                source="ownerDetails.responsible"
                                                label='resources.businesses.page.ownerDetails.responsible'
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="2em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.responsibleOfficerDetails.details'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.title"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.title'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={4} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.firstName"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.firstName'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <DateInput
                                                source="responsibleOfficerDetails.dateOfBirth"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.dateOfBirth'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.displaySsn"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.displaySsn'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.lastName"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.lastName'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.email"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.email'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.responsibleOfficerDetails.driverLicense.details'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.driverLicence.displayLicenseNumber"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.driverLicense.displayLicenseNumber'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <DateInput
                                                source="responsibleOfficerDetails.driverLicence.expiryDate"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.driverLicense.expiryDate'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <DateInput
                                                source="responsibleOfficerDetails.driverLicence.issuingDate"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.driverLicense.issuingDate'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="responsibleOfficerDetails.driverLicence.issuingState"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.responsibleOfficerDetails.driverLicense.issuingState'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.invoicingDetails.details'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.invoicingDetails.invoicingAddress.address'
                                        )}
                                    </Typography>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="invoicingDetails.invoicingAddress.addressLineOne"
                                                resource="businesses"
                                                label='resources.businesses.page.invoicingDetails.invoicingAddress.addressLineOne'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="businessAddress.addressLineTwo"
                                                resource="businesses"
                                                label='resources.businesses.page.invoicingDetails.invoicingAddress.addressLineTwo'
                                                multiline
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="invoicingDetails.invoicingAddress.city"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.invoicingDetails.invoicingAddress.city'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                            <TextInput
                                                source="invoicingDetails.invoicingAddress.addressState"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.invoicingDetails.invoicingAddress.addressState'
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box flex={2}>
                                            <TextInput
                                                source="invoicingDetails.invoicingAddress.zipcode"
                                                resource="businesses"
                                                fullWidth
                                                label='resources.businesses.page.invoicingDetails.invoicingAddress.zipcode'
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.invoicingDetails.emptyTitle'
                                        )}
                                    </Typography>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <NumberInput
                                            step={1}
                                            source="invoicingDetails.invoicePrefix"
                                            label='resources.businesses.page.invoicingDetails.invoicePrefix'
                                            resource="businesses"
                                            fullWidth
                                            helperText={false}
                                        />
                                    </Box>
                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <TextInput
                                            source="invoicingDetails.termsAndConditionsDesc"
                                            resource="businesses"
                                            label='resources.businesses.page.invoicingDetails.termsAndConditionsDesc'
                                            multiline
                                            fullWidth
                                            helperText={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>


                            {/* TODO enter CPM info*/}
                            <Separator/>
                            <Box mt="1em"/>

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.page.cpm.details'
                                )}
                            </Typography>


                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.cpm.eligible'
                                        )}
                                    </Typography>
                                    <SimpleShowLayout spacing={2} divider={<Divider flexItem/>} sx={{
                                        border: '1px solid',
                                        borderRadius: '3px',
                                        color: 'lightgray'
                                    }}>
                                        <ArrayField source="consumerPaymentMethods.eligible" label='' >
                                            <Datagrid bulkActionButtons={false} sx={{
                                                background: "#F5F5F5"
                                            }}>
                                                <TextField source="paymentMethod"
                                                           label='resources.businesses.page.cpm.paymentMethod'/>
                                                <TextField source="paymentProcessor"
                                                           label='resources.businesses.page.cpm.paymentProcessor'/>
                                                <TextField source="serviceFeeStructure.percentMax"
                                                           label='resources.businesses.page.cpm.percentMax'/>
                                                <TextField source="serviceFeeStructure.costFixed"
                                                           label='resources.businesses.page.cpm.costFixed'/>
                                            </Datagrid>
                                        </ArrayField>
                                    </SimpleShowLayout>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.businesses.page.cpm.enabled'
                                        )}
                                    </Typography>
                                    <SimpleShowLayout spacing={2} divider={<Divider flexItem/>} sx={{
                                        border: '1px solid', borderRadius: '3px', color: 'lightgray'
                                    }}>
                                        <ArrayField source="consumerPaymentMethods.enabled" label=''>
                                            <Datagrid bulkActionButtons={false} sx={{
                                                background: "#F5F5F5"
                                            }}>
                                                <TextField source="paymentMethod"
                                                           label='resources.businesses.page.cpm.paymentMethod'/>
                                                <TextField source="paymentProcessor"
                                                           label='resources.businesses.page.cpm.paymentProcessor'/>
                                                <TextField source="serviceFeeStructure.percentMax"
                                                           label='resources.businesses.page.cpm.percentMax'/>
                                                <TextField source="serviceFeeStructure.costFixed"
                                                           label='resources.businesses.page.cpm.costFixed'/>
                                                <TextField source="paymentMethodStatus"
                                                           label='resources.businesses.page.cpm.paymentMethodStatus'/>
                                            </Datagrid>
                                        </ArrayField>
                                    </SimpleShowLayout>
                                </Box>
                            </Box>
                            <Separator/>
                            <Box mt="1em"/>
                            <MerchantKeysInfo/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.fieldGroups.identity'
                                )}
                            </Typography>
                            <div>
                                <SelectInput
                                    resource="businesses"
                                    source="businessState"
                                    helperText={false}
                                    label='resources.businesses.page.businessProfile.businessState'
                                    choices={[
                                        {
                                            id: 'Draft',
                                            name: 'Draft',
                                        },
                                        {
                                            id: 'Pending',
                                            name: 'Pending',
                                        },
                                        {
                                            id: 'Approved',
                                            name: 'Approved',
                                        },
                                        {
                                            id: 'Disabled',
                                            name: 'Disabled',
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="businessId"
                                    source="businessId"
                                    resource="businesses"
                                    fullWidth
                                    label='resources.businesses.page.businessProfile.businessId'
                                    validate={[required()]}
                                    disabled={true}
                                    helperText={false}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="userid"
                                    source="userId"
                                    resource="businesses"
                                    label='resources.businesses.page.businessProfile.userId'
                                    validate={[required()]}
                                    disabled={true}
                                    helperText={false}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="clientId"
                                    source="clientId"
                                    resource="businesses"
                                    label='resources.businesses.page.businessProfile.clientId'
                                    helperText={false}
                                />
                            </div>
                            <div>
                                <TextInput
                                    source="contactPhone"
                                    resource="businesses"
                                    label='resources.businesses.page.businessProfile.contactPhone'
                                    helperText={false}
                                />
                            </div>
                            <div>
                                <DateTimeInput
                                    source="createdAt"
                                    resource="businesses"
                                    label='resources.businesses.page.businessProfile.createdAt'
                                    helperText={false}
                                    disabled={true}
                                />
                            </div>
                            <Separator/>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.businesses.fieldGroups.features'
                                )}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{
                                color: "#40BFD9",
                                fontStyle: "italic"
                            }}>
                                {translate(
                                    'resources.businesses.features.payoutLabel'
                                )}
                            </Typography>
                            <Box display={{xs: 'block', sm: 'flex'}}>
                                <Box
                                    flex={1}
                                    mr={{xs: 0, sm: '0.5em'}}
                                >
                                    <BooleanInput
                                        row={true}
                                        resource="businesses"
                                        source="payoutEnabled"
                                        label='resources.businesses.features.payout'
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <ReceiptFeatureInfo/>
                            <CardKnoxFeatureInfo/>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

const BusinessTitle = () => <BusinessLegalNameField/>;

export default BusinessEdit;
