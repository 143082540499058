import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import RelatedTransactionList from './RelatedTransactionList';
import TransactionEdit from "../transactions/TransactionEdit";

export default {
    list: RelatedTransactionList,
    edit: TransactionEdit,
    TransactionIcon: KeyboardArrowRightIcon,
};
