import * as React from 'react';
import {FieldProps, useRecordContext} from 'react-admin';

import {Business} from '../types';
import {Typography} from "@mui/material";

const BusinessLegalNameField = (props: FieldProps<Business>) => {
    const record = useRecordContext<Business>();
    if (!record) {
        return null;
    }
    return (
        <Typography
            variant="body2"
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            component="div"
            sx={{margin: '5px 0'}}
        >
            {record.businessProfile.businessLegalName}
        </Typography>
    );
};

BusinessLegalNameField.defaultProps = {
    source: 'business_id',
    label: 'Business Legal Name'
};

export default BusinessLegalNameField;

