import * as React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Business } from '../types';
import {
    Avatar, SxProps,
} from "@mui/material";

interface Props extends FieldProps<Business> {
    sx?: SxProps;
    size?: string;
}

const AvatarField = ({ size = '25', sx }: Props) => {
    const record = useRecordContext<Business>();
    if (!record) return null;
    return (
        <Avatar
            src={`${record.avatar}?size=${size}x${size}`}
            style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
            sx={sx}
        />
    );
};

export default AvatarField;
