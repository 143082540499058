import * as React from "react";
import {
  blue,
  blueGrey,
  deepOrange,
  deepPurple,
  green,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import { TextField, useRecordContext } from "react-admin";
import {Directory} from "../types";

const DirectoryStatusField = () => {
  const record = useRecordContext<Directory>();
  return (
    <TextField
      record={record}
      source="directoryStatus"
      sx={{ color: getStatusColor(record?.directoryStatus) }}
    />
  );
};

DirectoryStatusField.defaultProps = {
  source: "directoryStatus",
  label: "Directory Status",
};

function getStatusColor(state: string | undefined) {
  if (state) {
    return deepPurple["900"];
  }

  // fallback
  return "#000000";
}

export default DirectoryStatusField;
