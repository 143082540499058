import * as React from 'react';
import {
    ListItem,
    ListItemSecondaryAction,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslate, useReference } from 'react-admin';

import { Business, Transaction } from '../types';

interface Props {
    transaction: Transaction;
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const PendingTransaction = (props: Props) => {
    const { transaction } = props;
    const translate = useTranslate();
    const { referenceRecord: business, isLoading } = useReference<Business>({
        reference: 'businesses',
        id: transaction.businessId,
    });

    return (
        <ListItem button component={Link} to={`/transactions/${transaction.id}`}>
            <ListItemText
                primary={new Date(transaction.lastUpdatedAt).toLocaleString('en-US')}
                secondary={translate('pos.dashboard.transaction.items', {
                    customer_name:transaction.customerName,
                    id: transaction.id,
                    nb_items: 1,
                    business_name: business
                        ? `${business?.businessProfile?.businessLegalName}`
                        : '',
                })}
            />
            <ListItemSecondaryAction>
                <Box
                    component="span"
                    sx={{
                        marginRight: '.1em',
                        color: 'text.primary',
                    }}
                >
                    {formatter.format(transaction.totalAmount)}
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
